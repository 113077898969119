<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
      <v-card-title style="height: 60px">
        <h4>เชื่อมต่อ</h4>
      </v-card-title>
    </v-card>

    <!-- shopee -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://seeklogo.com/images/S/shopee-logo-DD5CAE562A-seeklogo.com.png"
        /><span class="ml-3">เชื่อมต่อ Shopee</span>
        <v-spacer />
        <v-btn
          outlined
          width="160"
          @click="(editVrich = false), open_dialog_topup('shopee')"
          color="primary"
          ><v-icon left>fa-plus</v-icon> เชื่อมต่อ Shopee</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_shopee_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_shopee"
                :items="connect_shopee_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.no="{ item }">
                  {{ connect_shopee_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.isAutoInit="{ item }">
                  <v-row align="center">
                    <v-col align="center">
                      <div class="d-flex">
                        <v-switch
                          @change="update_IsAutoInit(item.id, item.isAutoInit, 'shopee')"
                          width="50px"
                          hide-details
                          v-model="item.isAutoInit"
                        >
                        </v-switch>
                        <v-btn @click="openDialogEdit(item, 'shopee')" v-if="item.isAutoInit == true" text color="warning">แก้ไข</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.shopeeShopName="{ item }">
                  {{  item.shopeeShopName  }}<span style="color:red">  {{  item.remark  }}</span>
                </template>
                <template v-slot:item.created="{ item }">
                  {{ set_format_date_time(item.created) }}
                </template>
                <template v-slot:item.expired="{ item }">
                  {{ set_format_date_time(item.refresh_expired) }}
                </template>
                <template v-slot:item.isDelete="{ item }">
                  <a @click="DeleteShopShopee(item)"
                    ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อร้านค้า Shopee</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- lazada -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://www.blognone.com/sites/default/files/styles/thumbnail/public/topics-images/laz.png?itok=rN2J8uw-"
        /><span class="ml-3">เชื่อมต่อ Lazada</span>
        <v-spacer />
        <v-btn
          outlined
          width="160"
          @click="(editVrich = false), open_dialog_topup('lazada')"
          color="primary"
          ><v-icon left>fa-plus</v-icon> เชื่อมต่อ Lazada</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_lazada_data.length > 0"
            >

            <v-data-iterator
              :items="connect_lazada_data"
              :items-per-page="9999999999"
              hide-default-header
              hide-default-footer 
              style="color:black"
            >
              <template v-slot:default="{ items }">
                <v-card  v-for="item in items" :key="item.id"  style="border: 1px solid #d7d7d7;color:black">
                  <v-card-text style="color:black">
                    <v-row style="border-bottom: 1px solid #d7d7d7">
                      <v-col   cols="1" align="left">
                        <b>ลำดับ</b>
                      </v-col>
                      <v-col cols="2" align="left">
                        <b>ID ร้านค้า</b>
                      </v-col>
                      <v-col cols="3" align="left">
                        <b>ชื่อร้าน</b>
                      </v-col>
                      <v-col cols="2" align="left">
                        <b>วันที่สร้าง</b>
                      </v-col>
                      <v-col cols="2" align="left">
                        <b>หมดอายุ</b>
                      </v-col>
                      <v-col cols="1" align="left">
                        <b>นัดรับอัตโนมัติ</b>
                      </v-col>
                      <v-col cols="1" align="center">
                        <b>ลบ</b> 
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="1" align="left">
                        {{ connect_lazada_data.indexOf(item) + 1 }}
                      </v-col>
                      <v-col cols="2" align="left">
                        {{item.lazadaSellerId  }} 
                      </v-col>
                      <v-col cols="3" align="left">
                        {{item.lazadaShopName  }} 
                      </v-col>
                      <v-col cols="2" align="left">
                        {{set_format_date_time(item.createDateTime)  }} 
                      </v-col>
                      <v-col cols="2" align="left">
                        {{set_format_date_time(item.refreshExpiresIn)  }} 
                      </v-col>
                      <v-col cols="1" align="left">
                        <v-switch
                          @change="update_IsAutoInit(item.id, item.isAutoInit, 'lazada')"
                          width="50px"
                          hide-details
                          v-model="item.isAutoInit"
                        ></v-switch>
                        <v-btn @click="openDialogEdit(item, 'lazada')" v-if="item.isAutoInit == true" text color="warning">แก้ไข</v-btn>
                      </v-col>
                      <v-col cols="1" align="center">
                        <a @click="DeleteShopLazada(item)" ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                      </v-col>
                    </v-row>
                 
                  </v-card-text>

                  <v-card-text> 
                    <v-card-text> 
                      <v-row>
                        <v-col cols="6">
                          คลังที่เชื่อมต่อ
                        </v-col>
                        <v-col cols="6" align="right">
                        <a @click="GetShopLazadaWarehouseForAdd(item)">+ เพิ่มคลัง</a> 
                        </v-col >
                      </v-row>
                      <v-data-table
                        :headers="headers_lazada_warehouse"
                        :items="item.warehouseList"
                        class="elevation-0 packhai-border-table"
                        hide-default-footer
                        :items-per-page="10000"
                        mobile-breakpoint="0"
                      > 
                      <template v-slot:item.isDelete="{ item }">
                        <a @click="DeleteShopLazadaWarehouse(item)" >
                          <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                        </a>
                      </template>
                    </v-data-table>

                    </v-card-text>
                  </v-card-text>
                </v-card>
               
              </template>
            </v-data-iterator>
 
              <!-- <v-data-table
                :headers="headers_lazada"
                :items="connect_lazada_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.no="{ item }">
                  {{ connect_lazada_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.isAutoInit="{ item }">
                  <v-row align="center">
                    <v-col align="center"
                      ><v-switch
                        @change="update_IsAutoInit(item.id, item.isAutoInit, 'lazada')"
                        width="50px"
                        hide-details
                        v-model="item.isAutoInit"
                      ></v-switch
                    ></v-col>
                  </v-row>
                </template>
                <template v-slot:item.lazadaShopName="{ item }">
                  {{  item.lazadaShopName  }} <span style="color:red">  {{  item.remark  }}</span>
                </template>
                <template v-slot:item.createDateTime="{ item }">
                  {{ set_format_date_time(item.createDateTime) }}
                </template>
                <template v-slot:item.expired="{ item }">
                  {{ set_format_date_time(item.refreshExpiresIn) }}
                </template>
                <template v-slot:item.isDelete="{ item }">
                  <a @click="DeleteShopLazada(item)"
                    ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table> -->
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อร้านค้า Lazada</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

 
    <!-- tiktok -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/TikTok-Logo-1.png"
        /><span class="ml-3">เชื่อมต่อ Tiktok</span>
        <v-spacer />
        <v-btn
          outlined
          width="160"
          @click="(editVrich = false), open_dialog_topup('tiktok')"
          color="primary"
          ><v-icon left>fa-plus</v-icon> เชื่อมต่อ Tiktok</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_tiktok_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_tiktok"
                :items="connect_tiktok_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ connect_tiktok_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.tiktok_shop_id="{ item }">
                  <!-- <div :style="'max-width:'+(window.width/2)+'px'">{{ item.access_token }} <a @click="copyTokenVrich(item.access_token)"><v-icon size="18" left>mdi-content-copy</v-icon></a> </div> -->
                  {{ item.tiktok_shop_id }}
                </template>
                <template v-slot:item.shop_name="{ item }">
                {{  item.shop_name  }}  <span style="color:red">  {{  item.remark  }}</span>
                </template>
                <template v-slot:item.delete="{ item }">
                  <a @click="DeleteApiauthen(item, 'tiktok')"
                    ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
                <template v-slot:item.createDateTime="{ item }">
                  {{ set_format_date_time(item.createDateTime) }}
                </template>
                <template v-slot:item.expired="{ item }">
                  {{ set_format_date_time(item.refresh_expires_in) }}
                </template>
                <template v-slot:item.isAutoInit="{ item }">
                  <v-row align="center">
                    <v-col align="center">
                      <div class="d-flex">
                        <v-switch
                          @change="update_IsAutoInit(item.id, item.isAutoInit, 'tiktok')"
                          width="50px"
                          hide-details
                          v-model="item.isAutoInit"
                        >
                        </v-switch>
                        <v-btn @click="openDialogEdit(item, 'tiktok')" v-if="item.isAutoInit == true" text color="warning">แก้ไข</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อร้านค้า tiktok</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

     <!-- line my shop -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title class="height-57">
        <v-img
          style="max-width: 30px !important"
          src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/linemyshop.png"
        /><span class="ml-3">เชื่อมต่อ Line MyShop</span>
        <a
          class="pl-2"
          style="font-size: 12px"
          href="https://docs.google.com/document/d/1NkJGRPvti0kXgr-uBAZUwhvkVMSqk_ywOaloG8d4SAQ/edit?usp=sharing"
          target="_blank"
        >
          <b>วิธีการเชื่อมต่อ Line MyShop</b></a
        >
        <v-spacer />
        <v-btn width="160" @click="open_dialog_line_my_shop(null, 'add')" color="primary" outlined
          ><v-icon left>fa-plus</v-icon> Line MyShop</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <input
          id="token-line-my-shop"
          style="width: 100%"
          type="hidden"
          v-model="token_line"
        />
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="line_my_shop_data_list.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_line_my_shop"
                :items="line_my_shop_data_list"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ line_my_shop_data_list.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.token="{ item }">
                  <div :style="'max-width:' + window.width / 2 + 'px'">
                    {{ item.token }}
                    <a @click="copyTokenLineMyshop(item.token)"
                      ><v-icon size="18" left>mdi-content-copy</v-icon></a
                    >
                  </div>
                </template>
                <template v-slot:item.callBackUrl="{ item }">
                  <div :style="'max-width:' + window.width / 2 + 'px'">
                    {{ item.callBackUrl }}
                    <a @click="copyTokenLineMyshop(item.callBackUrl)"
                      ><v-icon size="18" left>mdi-content-copy</v-icon></a
                    >
                  </div>
                </template>
                <template v-slot:item.edit="{ item }">
                  <a @click="open_dialog_line_my_shop(item, 'edit')"
                    ><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a
                  >
                </template>
                <template v-slot:item.delete="{ item }">
                  <a @click="DeleteApiauthen(item, 'lineMyShop')"
                    ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อ Line MyShop</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- woo -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/woo.png"
        /><span class="ml-3">เชื่อมต่อ Woo Commerce</span>
        <v-spacer />
        <v-btn
          outlined
          width="160"
          @click="SetShopWoo(null), (editVrich = false), open_dialog_topup('woo')"
          color="primary"
          ><v-icon left>fa-plus</v-icon> เชื่อมต่อ Woo</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_woo_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_woo"
                :items="connect_woo_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ connect_woo_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.edit="{ item }">
                  <a
                    @click="
                      SetShopWoo(item), (editVrich = false), open_dialog_topup('woo')
                    "
                    ><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a
                  >
                </template>
                <template v-slot:item.delete="{ item }">
                  <a @click="DeleteWoo(item)"
                    ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อ woo</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

   <!-- shopify -->
   <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/Screenshot+2024-03-09+004759.png"
        /><span class="ml-3">เชื่อมต่อ Shopify</span>
        <a
          class="pl-2"
          style="font-size: 12px"
          href="https://docs.google.com/document/d/1DAxak509gDZw-5ULROYo6iMrstGoC7HHfdzH3p3O2Cs/edit?usp=sharing"
          target="_blank"
        >
          <b>วิธีการเชื่อมต่อ Shopify</b></a
        >
        <v-spacer />
        <v-btn
          outlined
          width="160"
          @click="SetShopShopify(null), (editVrich = false), open_dialog_topup('shopify')"
          color="primary"
          ><v-icon left>fa-plus</v-icon> เชื่อมต่อ Shopify</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_shopify_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_shopify"
                :items="connect_shopify_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ connect_shopify_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.edit="{ item }">
                  <a
                    @click="
                      SetShopShopify(item), (editVrich = false), open_dialog_topup('shopify')
                    "
                    ><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a
                  >
                </template>
                <template v-slot:item.delete="{ item }">
                  <a @click="DeleteShopify(item)"
                    ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อ shopify</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <!-- vrich -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://dewteerapat.vrich619.com/img/logo.png"
        /><span class="ml-3">เชื่อมต่อ Vrich</span>
        <v-spacer />
        <v-btn
          outlined
          width="160"
          @click="(editVrich = false), open_dialog_topup('vrich')"
          color="primary"
          ><v-icon left>fa-plus</v-icon> เชื่อมต่อ Vrich</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <input id="token-vrich" style="width: 100%" type="hidden" v-model="token" />
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_vrich_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_vrich"
                :items="connect_vrich_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ connect_vrich_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.token="{ item }">
                  <div :style="'max-width:' + window.width / 2 + 'px'">
                    {{ item.token }}
                    <a @click="copyTokenVrich(item.token)"
                      ><v-icon size="18" left>mdi-content-copy</v-icon></a
                    >
                  </div>
                </template>
                <template v-slot:item.edit="{ item }">
                  <a @click="Edit_Vrich(item), open_dialog_topup('vrich')"
                    ><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a
                  >
                </template>
                <template v-slot:item.delete="{ item }">
                  <a @click="DeleteApiauthen(item, 'vrich')"
                    ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อร้านค้า Vrich</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- zwiz -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/zwizlogo.jpg"
        /><span class="ml-3">เชื่อมต่อ zwiz</span>
        <v-spacer />
        <v-btn
          outlined
          width="160"
          @click="(editVrich = false), open_dialog_topup('zwiz')"
          color="primary"
          ><v-icon left>fa-plus</v-icon> เชื่อมต่อ zwiz</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_zwiz_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_zwiz"
                :items="connect_zwiz_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ connect_zwiz_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.token="{ item }">
                  <div :style="'max-width:' + window.width / 2 + 'px'">
                    {{ item.token }}
                    <a @click="copyTokenVrich(item.token)"
                      ><v-icon size="18" left>mdi-content-copy</v-icon></a
                    >
                  </div>
                </template>
                <template v-slot:item.delete="{ item }">
                  <a @click="DeleteApiauthen(item, 'zwiz')"
                    ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อ zwiz</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>



    <!-- line notify -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/line.png"
        /><span class="ml-3">เชื่อมต่อ Line Notify</span>
        <v-spacer />
        <v-btn
          outlined
          width="160"
          @click="(editVrich = false), open_dialog_topup('line notify')"
          color="primary"
          ><v-icon left>fa-plus</v-icon>Line Notify</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_line_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_line"
                :items="connect_line_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ connect_line_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.token="{ item }">
                  {{ item.token }}
                </template>
                <template v-slot:item.typeName="{ item }">
                  {{ item.typeName + " " + (item.time != null ? item.time : "") }}
                </template>
                <template v-slot:item.delete="{ item }">
                  <a @click="DeleteApiauthen(item, 'linenotify')"
                    ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อ Line Notify</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- line oa -->
    <v-card elevation="1" class="v-card-bottom-30" v-if="parseInt(this.ShopID) == 2 || parseInt(this.ShopID) == 4071 || this.StaffID == 6586">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/lineoa.png"
        /><span class="ml-3">เชื่อมต่อ Line OA</span>
        <v-spacer />
        <v-btn
          outlined
          width="160"
          @click="(editVrich = false), open_dialog_topup('lineOa')"
          color="primary"
          ><v-icon left>fa-plus</v-icon>Line OA</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_lineoa_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_lineoa"
                :items="connect_lineoa_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ connect_lineoa_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.webhookUrl="{ item }">
                  <div>
                    {{ item.webhookUrl }}
                    <a @click="copyTokenVrich(item.webhookUrl)"
                      ><v-icon size="18" left>mdi-content-copy</v-icon></a
                    >
                  </div>
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อ Line OA</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- fackbook-->
    <v-card elevation="1" class="v-card-bottom-30" v-if="parseInt(this.ShopID) == 2 || parseInt(this.ShopID) == 4071 || this.StaffID == 6586">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://rtms3.s3.ap-southeast-1.amazonaws.com/packhai/packhai_shop/logo-facebook.png"
        /><span class="ml-3">เชื่อมต่อ Fackbook</span>
        <v-spacer />
        <v-btn
          outlined
          width="160"
          @click="(editVrich = false), open_dialog_topup('fackbook')"
          color="primary"
          ><v-icon left>fa-plus</v-icon>Fackbook</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_fackbook_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_fackbook"
                :items="connect_fackbook_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ connect_fackbook_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.pagePictureURL="{ item }">
                  <img style="width: 60px" :src="item.pagePictureURL" @click="image_click(item.pagePictureURL)"/>
                </template>
                <template v-slot:item.created="{ item }">
                  {{ set_format_date_time(item.created) }}
                </template>
                <template v-slot:item.isDelete="{ item }">
                  <a @click="deleteFacebook(item)">
                    <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                  </a>
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อ Fackbook</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- fackbook 2-->
    <!-- <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-img
          style="max-width: 30px !important"
          src="https://rtms3.s3.ap-southeast-1.amazonaws.com/packhai/packhai_shop/logo-facebook.png"
        /><span class="ml-3">เชื่อมต่อ Facebook</span>
        <v-spacer />
          <v-btn
            outlined
            width="160"
            @click="(editVrich = false), open_dialog_topup('fackbook')"
            color="primary"
          ><v-icon left>fa-plus</v-icon>Fackbook</v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_fackbook_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_fackbook"
                :items="connect_fackbook_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ connect_fackbook_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.pagePictureURL="{ item }">
                  <img style="width: 60px" :src="item.pagePictureURL"/>
                </template>
                <template v-slot:item.created="{ item }">
                  {{ set_format_date_time(item.created) }}
                </template>
                <template v-slot:item.isDelete="{ item }">
                  <a @click="deleteFacebook(item)">
                    <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                  </a>
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อ Fackbook</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->

    <!-- callbacl url -->
    <!-- <v-card v-if="shop.is_Active" elevation="1" class="v-card-margin-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="40" color="info">mdi-transfer</v-icon><span class="ml-2">ตั้งค่า url callback</span>
        <v-btn @click="delete_shop_call_back_url()" icon><v-icon size="20" color="danger">mdi-delete</v-icon></v-btn>
        <v-spacer/>
        <v-switch v-model="data_callback.isActive" @change="add_update_shop_call_back_url()" label="สถานะ" color="success" class="px-3" hide-details></v-switch>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pt-5">
            <span> <b>Call Back Url</b> : </span>
          </v-col>
          <v-col align="left" cols="12" md="4" lg="4">
            <v-text-field
              v-model="data_callback.urlCallback"
              ref="refUrlCallback"
              autocomplete="off"
              :disabled="!data_callback.isActive"
              hide-details
              dense
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-divider/>


        <v-row>
          <v-col class="pb-4" align="left" cols="6" md="10">
            <b>สถานะ</b>
          </v-col>
          <v-col class="pb-4" align="left" cols="6" md="2">
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-1" align="left" cols="6" md="10">
            <b>รอแพ็ค</b>
          </v-col>
          <v-col class="py-1" align="center" cols="6" md="2">
            <v-switch
              v-model="data_callback.statusWaitPack"
              :disabled="!data_callback.isActive"
              @change="add_update_shop_call_back_url()"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-1" align="left" cols="6" md="10">
            <b>แพ็คเรียบร้อย</b>
          </v-col>
          <v-col class="py-1" align="center" cols="6" md="2">
            <v-switch
              v-model="data_callback.statusPacked"
              :disabled="!data_callback.isActive"
              @change="add_update_shop_call_back_url()"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-1" align="left" cols="6" md="10">
            <b>ขนส่งเข้ารับ</b>
          </v-col>
          <v-col class="py-1" align="center" cols="6" md="2">
            <v-switch
              v-model="data_callback.statusReady"
              :disabled="!data_callback.isActive"
              @change="add_update_shop_call_back_url()"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-1" align="left" cols="6" md="10">
            <b>จัดส่งสำเร็จ</b>
          </v-col>
          <v-col class="py-1" align="center" cols="6" md="2">
            <v-switch
              v-model="data_callback.statusSent"
              :disabled="!data_callback.isActive"
              @change="add_update_shop_call_back_url()"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-1" align="left" cols="6" md="10">
            <b>ยกเลิก</b>
          </v-col>
          <v-col class="py-1" align="center" cols="6" md="2">
            <v-switch
              v-model="data_callback.statusCancel"
              :disabled="!data_callback.isActive"
              @change="add_update_shop_call_back_url()"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1" align="right" cols="12">
            <v-btn :disabled="!data_callback.isActive" @click="add_update_shop_call_back_url()" color="primary"> บันทึก </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card> -->

    <!-- token -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="30" color="warning">mdi-link</v-icon
        ><span class="ml-2">สร้าง Token สำหรับเชื่อมต่อจากภายนอก</span>
         <a
          class="pl-2"
          style="font-size: 12px"
          href="https://partner.packhai-api-88.com/swagger/index.html"
          target="_blank"
        >
          <b>API Document</b></a
        >
        <v-spacer />
        <v-btn width="160" @click="open_dialog_token()" color="primary" outlined
          ><v-icon left>fa-plus</v-icon> สร้าง Token</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row row>
          <v-col class="pb-0" cols="12" md="12">
            <div
              v-if="connect_token_data.length > 0"
              style="max-height: 500px; min-height: 150px"
              class="overflow-y-auto"
            >
              <v-data-table
                :headers="headers_token"
                :items="connect_token_data"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.No="{ item }">
                  {{ connect_token_data.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.token="{ item }">
                  <div :style="'max-width:' + window.width / 4 + 'px'">
                    {{ item.token }}
                    <a @click="copyTokenVrich(item.token)"
                      ><v-icon size="18" left>mdi-content-copy</v-icon></a
                    >
                  </div>
                </template>
                <template v-slot:item.webhookSetting="{ item }">
                  <a @click="open_dialog_update_webhook(item, 'token')"
                    > ตั้งค่า</a
                  >
                </template>
                <template v-slot:item.delete="{ item }">
                  <a @click="DeleteApiauthen(item, 'token')"
                    ><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table>
            </div>
            <div v-else align="center">
              <h3>ไม่มีการเชื่อมต่อ</h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- peak -->
    <v-card elevation="1" class="v-card-margin-bottom-30" v-show="peak.FirstTimeCheck">
        <v-card-title class="height-70">
            <img src="https://www.uob.co.th/bizsmart/assets/img/logos/peak-logo.png" height="70%"/>
        <span class="ml-2">เชื่อมต่อ PEAK</span>
        <v-spacer />
        <v-switch
          v-model="peak.IsDelete"
          :label="StockMarketplaceActive(peak.IsDelete)"
          color="success"
          class="px-3"
          hide-details
        ></v-switch>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="2" class="py-5">
            <h3 class="fn-14 mb-3">Token จากระบบ PEAK</h3>
          </v-col>
          <v-col cols="4">
            <v-text-field
                    v-model="peak.Token"
                    autocomplete = "off"
                    outlined
                    required
                    danse hide-details
                    ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              :elevation="1"
              color="primary"
              @click="handleCheckTokenAuthenClick()"
            >
              <!-- <v-icon size="20" class="mr-1">mdi-content-save</v-icon> -->
              <span class="fn-14"><b> บันทึกToken </b></span>
            </v-btn>
          </v-col>
          <v-col class="py-4" align="left"> 
            <v-icon v-if="peak.PaymentMethodCheck === 1" size="18" left color="green">mdi-check-bold </v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="py-5">
            <h3 class="fn-14 mb-3">การส่งข้อมูลเข้าระบบ PEAK</h3>
          </v-col>
          <v-col cols="10">
            <v-radio-group
                                row
                                required
                                class="mb-3 is-cod-selector"
                                dense hide-details
                                :disabled="!peak.nextStepAuthen"
                                v-model="peak.sendOrder"
                            >
                                <v-radio label="ส่งข้อมูลทุกออเดอร์เข้าระบบ PEAK" :value="true"> 
                                    <template v-slot:label> <span>ส่งข้อมูลทุกออเดอร์เข้าระบบ PEAK</span></template>
                                </v-radio>
            </v-radio-group>
          <v-row v-if="peak.sendOrder">
                  <span class="pl-5 pr-4 pt-3"> รหัสผู้ติดต่อ </span>
                  <v-text-field
                    :value="peak.contact"
                    @change="(value) => (peak.contact = Trim_value(value))"
                    outlined
                    dense
                    hide-details
                    autocomplete="off"
                    class="maxwidth-150"
                  ></v-text-field>
                  <!-- <v-icon v-if=".contacValidate == 1" size="18" left color="green">mdi-check-bold </v-icon>
                  <v-icon v-else-if="j.contacValidate == 2" size="18" left color="red">mdi-close</v-icon> -->
                    
                  <span class="pl-5 pr-4 pt-3"> ช่องทางการเงิน </span>
                  <v-text-field
                    :value="peak.MethodCode"
                    @change="(value) => (peak.MethodCode = Trim_value(value))"
                    outlined
                    dense
                    hide-details
                    autocomplete="off"
                    class="maxwidth-150"
                  ></v-text-field>
                  <!-- <v-icon v-if="j.productIDValidate === 1" size="18" left color="green">mdi-check-bold </v-icon>
                  <v-icon v-else-if="j.productIDValidate === 2" size="18" left color="red">mdi-close</v-icon> -->
          </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="py-5">
          </v-col>
          <v-col cols="10">
            <v-radio-group
                                row
                                required
                                class="mb-2 is-cod-selector"
                                dense hide-details
                                :disabled="!peak.nextStepAuthen"
                                v-model="peak.sendOrder"
                            >
                            <v-radio label="ส่งข้อมูลผลรวมทุกออเดอร์เข้าระบบ PEAK รายวัน(เวลา 24.00 น.)" :value="false">
                                  <template v-slot:label> <span>ส่งข้อมูลผลรวมทุกออเดอร์เข้าระบบ PEAK รายวัน(เวลา 24.00 น.)</span> </template>
                            </v-radio>
            </v-radio-group>
          <v-row v-if="!peak.sendOrder">
          <v-col cols="12">
            <span v-for="(j, indexj) in peak.marketplaceList" :key="indexj">
              <span v-if="j.isActive" class="v-list-item">
                <v-checkbox
                  v-model="j.select"
                  color="info"
                  hide-details
                  :disabled="!peak.nextStepAuthen"
                  @click="handleSelectCheckboxMarketplace(j)"
                ></v-checkbox>
                <v-avatar
                  v-if="j.photoLink != '' && j.photoLink != null"
                  size="25"
                  tile
                  style="padding: 1px"
                >
                  <img :src="j.photoLink" />
                </v-avatar>
                <span class="pl-5 pr-4"> รหัสผู้ติดต่อ </span>
                <v-text-field
                  :value="j.contac"
                  @change="(value) => (j.contac = Trim_value(value))"
                  outlined
                  dense
                  hide-details
                  :disabled='!j.select'
                  autocomplete="off"
                  class="maxwidth-150"
                  @blur="onChangeCheckContact(j,peak.marketplaceList.indexOf(j))"
                ></v-text-field>
                <v-icon v-if="j.contacValidate == 1" size="18" left color="green">mdi-check-bold </v-icon>
                <v-icon v-else-if="j.contacValidate == 2" size="18" left color="red">mdi-close</v-icon>
                <span class="pl-5 pr-4"> เลขที่สินค้า </span>
                <v-text-field
                  :value="j.productID"
                  @change="(value) => (j.productID = Trim_value(value))"
                  outlined
                  dense
                  hide-details
                  :disabled='!j.select'
                  autocomplete="off"
                  class="maxwidth-150"
                  @blur="onChangeCheckProduct(j,peak.marketplaceList.indexOf(j))"
                ></v-text-field>
                <v-icon v-if="j.productIDValidate === 1" size="18" left color="green">mdi-check-bold </v-icon>
                <v-icon v-else-if="j.productIDValidate === 2" size="18" left color="red">mdi-close</v-icon>
                <span class="pl-5 pr-4"> ช่องทางการเงิน </span>
                <v-text-field
                  :value="j.MethodCode"
                  @change="(value) => (j.MethodCode = Trim_value(value))"
                  outlined
                  dense
                  hide-details
                  :disabled='!j.select'
                  autocomplete="off"
                  class="maxwidth-150"
                ></v-text-field>
                <!-- <v-icon v-if="j.productIDValidate === 1" size="18" left color="green">mdi-check-bold </v-icon>
                <v-icon v-else-if="j.productIDValidate === 2" size="18" left color="red">mdi-close</v-icon> -->
              </span>
              
            </span>
          </v-col>
        </v-row>
          </v-col>
        </v-row>


        <v-row class="py-0">
          <v-col cols="12" md="12" class="py-0" align="right">
            <v-btn
              :elevation="1"
              color="primary"
              class="px-5"
              @click="handleUpdatePeakClick()"
              :disabled="!peak.nextStepAuthen"
            >
              <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
              <span class="fn-14"><b> บันทึก </b></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- marketplace -->
    <v-card   elevation="1" class="v-card-margin-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="30" color="orange darken-3">mdi-cog</v-icon
        ><span class="ml-2">ตั้งค่าการ Sync Stock</span>
        
        <v-spacer />
      
      </v-card-title>
      <v-divider />
      <v-card-text> 
        <v-data-table
                :headers="headers_syncStock"
                :items="syncStockInfoList"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000"
                mobile-breakpoint="0"
              >
                <template v-slot:item.edit="{ item }">
                  <a @click="handleEditSyncStockDialog(item)">แก้ไข</a>
                </template>
                <template v-slot:item.platformLogo="{ item }">
                  <v-avatar size="25px"  >
                      <img :src="item.platformLogo"/>
                    </v-avatar>
                </template>
               
                <template v-slot:item.isUpdateStock="{ item }">
                   <span  v-if="item.isUpdateStock" class="color-green text-center">
                    เปิดใช้งาน
                   </span>
                   <span v-else class="color-red text-center">
                    ไม่เปิดใช้
                  </span>
                </template>
                <template v-slot:item.updateStockPercent="{ item }">
                   <span v-if="item.updateStockPercent!=null" class="text-right" align="right" >
                     {{ formatMoney(item.updateStockPercent) }}%
                   </span>
                 </template>
           
                <template v-slot:item.remark="{ item }">
                  <span v-if="item.staticQuantityWhenLowStock!=null" class="text-right" align="right" >
                     เมื่อสต๊อกต่ำกว่า {{item.quantityLowStock}} ให้อัพเดทไปเป็น {{  item.staticQuantityWhenLowStock  }} 
                   </span>
                  
                </template>
                
        </v-data-table>
           
      </v-card-text>
    </v-card>
    <v-card v-if="shop.is_Active" elevation="1" class="v-card-margin-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="30" color="orange darken-3">mdi-refresh</v-icon
        ><span class="ml-2">sync stock ใหม่ทั้งหมด</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-btn v-if="!IsShowCancelSyncButton"   @click="ReSyncStock()" color="primary"><v-icon left>fa-rocket</v-icon> ดำเนินการ</v-btn>
        <v-btn v-else   @click="cancel_resync_stock()" outlined color="primary"><v-icon left>fa-ban</v-icon>ยกเลิกการ sync</v-btn>
        <span class="ml-2" v-if="IsShowPercentSyncProgress">{{PercentUpdateStock}}% ({{CurrentUpdateStock}}/{{ TotalUpdateStock }})</span>
        <span class="ml-2 color-green"  v-if="IsShowSyncSuccess">Sync Stock สำเร็จ</span>
      </v-card-text>
    </v-card>


    <!-- dialog -->
   <v-dialog v-model="sync_stock_dialog" scrollable persistent>
    <v-card>
      <v-card-title>
        แก้ไขการ Sync Stock
        <v-avatar size="25px" class="ml-4" >
          <img :src="currentsyncStockInfo.platformLogo"/>
        </v-avatar>
        <span class="ml-4">{{ currentsyncStockInfo.platformShopName }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="sync_stock_dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="2" md="3">
            <b>การ Sync : </b>
          </v-col>
          <v-col cols="10" md="9">
            <v-radio-group v-model="sync_stock_radio" @change="sync_stock_radio_changed()"  row>
              <v-radio label="เปิดใช้งาน" color="green" value="1"></v-radio>
              <v-radio label="ไม่เปิดใช้" color="red" value="0"></v-radio> 
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" md="3">
            <b> % การ Sync : </b>
          </v-col>
          <v-col cols="10" md="9">
            <table>
              <tr>
                <td>
                  <v-text-field style="width:100px"
                    v-model="sync_stock_percent"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                    type="number"
                  ></v-text-field>
                </td>
                <td class="pl-1">
                  %
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" md="3">
            <b> เมื่อสต๊อกต่ำกว่า : </b>
          </v-col>
          <v-col cols="10" md="9">
            <table>
              <tr>
                <td>
                  <v-text-field style="width:100px"
                    v-model="sync_stock_minimum"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                    type="number"
                  ></v-text-field>
                </td>
                <td class="pl-1">
                  ชิ้น
                </td>
              </tr>
            </table>
           
       
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" md="3"  >
           <b> ให้อัพเดทเป็น : </b>
          </v-col>
          <v-col cols="10" md="9">
            <table>
              <tr>
                <td>
                  <v-text-field style="width:100px"
                    v-model="sync_stock_static_quantity"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                    type="number"
                  ></v-text-field>
                </td>
                <td class="pl-1">
                  ชิ้น
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        
      </v-card-text>
      <v-divider />
      <v-card-actions >
        <v-checkbox v-model="sync_stock_updateall" label="อัพเดทการตั้งค่าไปยังสินค้าทุกรายการที่อยู่ในร้านนี้"></v-checkbox>
        <v-spacer></v-spacer>
        <div >
          <v-btn   @click="save_edit_sync_stock()" color="primary"><v-icon left>fa-save</v-icon> บันทึก</v-btn>
        </div>
      </v-card-actions>
    </v-card>
   </v-dialog>

    <v-dialog v-model="connect_dialog" scrollable  persistent  >
      <v-card class="my-0">
        <v-card-title>
          <span>{{ dialog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="connect_dialog = false, fb_selected=[], fb_page_lists =[]"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div v-if="token != null && !editVrich" align="center" class="my-2">
            <span> <b>สร้าง TOKEN สำเร็จ</b> </span><br /><br /><br />
            <v-textarea outlined :value="token" readonly></v-textarea>
            <input style="width: 100%" type="hidden" id="token-code" v-model="token" />
            <div>
              <v-btn color="primary" @click="copyToken()">คัดลอก TOKEN</v-btn>
            </div>
          </div>

          <div v-else>
            <!-- dialog shopee -->
            <div align="center" class="my-10" v-show="dialog_type == 'shopee'">
              <v-row>
                <v-col cols="6"  lg="6" align="right">
                  <v-img
                    class="elevation-3"
                    :src = "logoConnect"
                    width="190px"
                    height="190px"
                    style="border: 4px solid #fff; padding: 10px;"
                  />
                  <!-- src="https://rtms3.s3-ap-southeast-1.amazonaws.com/packhai/packhai_shop/image_2020_11_20T03_28_17_235Z.png" -->
                </v-col>
                <v-divider vertical ></v-divider>
                <v-col cols="6"  lg="6" align="left">                  
                  <v-img
                    class="elevation-3"
                    src="@/assets/shopee-logo-connect.png" 
                    width="190px"
                    height="190px"
                    style="border: 4px solid #fff; padding: 10px;"
                  />
                </v-col>
              </v-row>
            </div>
            <div
              align="center"
              class="my-10"
              v-if="this.IsDev == 'dev' && dialog_type == 'shopee'"
              >
              <v-btn dark><v-icon left>fa-link</v-icon> เชื่อมต่อ shopee</v-btn>
            </div>
            <div v-else-if="dialog_type == 'shopee'" align="center" class="my-10">
             
              <v-row>
                <v-col cols="12" md="3" lg="2"   align="left">คลังสินค้า : </v-col>
                <v-col
                  cols="12" md="9" lg="10" 
                
                  align="left"
                  class="py-1 font-weight-bold"
                  style="font-size: 12px !important"
                >
                  <v-select 
                      v-model="ShopeeWarehouse_Selected"
                      :items="Shopee_Warehouse"
                      item-text="name"
                      item-value="id"
                      persistent-hint
                      return-object
                      dense hide-details
                      outlined
                      single-line 
                  ></v-select>

                </v-col>
              </v-row>
              <v-row class="pb-1">
                <v-col cols="12" md="3" lg="2"   align="left">นัดรับอัตโนมัติ :</v-col>
                <v-col
                cols="12" md="9" lg="10" 
                 
                  align="left"
                  class="font-weight-bold"
                  style="font-size: 12px !important"
                >
                  <v-checkbox
                    v-model="check_init_shopee"
                    label="ต้องการให้ระบบทำการกดนัดรับโดยอัตโนมัติ"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <div v-if="check_init_shopee == true">
               <v-row class="pb-1">
                  <v-col cols="12" md="3" lg="2"   align="left">ตั้งค่านัดรับ :</v-col>
                  <v-col   cols="12" md="9" lg="10" align="left">
                    <table> 
                    <tr>
                        <td>เปิดใช้งาน</td>
                         <td>รูปแบบ</td>
                          <td>เวลาเริ่มต้น-สิ้นสุด</td>
                      <tr>
                      <tr
                        v-for="(item, index) in dayListShopee" :key="index"
                        class="font-weight-bold"
                        style="font-size: 12px !important"
                      >
                        <td >
                          <v-switch v-model="item.isActive" :label="daysLoop[index].text" @change="daysLoopActiveChanged(item)"/>
                        </td>
                        <td >
                          <v-radio-group row v-model="item.initType" :value="null"  >
                            <v-radio label="Pickup" :value="1"></v-radio>
                            <v-radio label="Drop-off" :value="2"></v-radio>
                          </v-radio-group>
                        </td>
                        <td >
                          <tr>
                            <td>
                              <v-select
                                v-model="item.startTime"
                                :items="items_time"
                                item-text="name"
                                item-value="value"
                                persistent-hint
                                dense hide-details
                                outlined
                                single-line
                                style="width:90px"
                                 
                              ></v-select>
                            </td>
                            <td class="pl-1 pr-1">-</td>
                            <td>
                              <v-select
                                v-model="item.endTime"
                                :items="items_time"
                                item-text="name"
                                item-value="value"
                                persistent-hint
                                dense hide-details
                                outlined
                                single-line
                                  style="width:90px"
                                
                              ></v-select>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table> 
                  </v-col>
                </v-row>
              </div>

              <v-row>
                <v-col cols="12" md="4" lg="3"   align="left"> </v-col>
                <v-col cols="12" md="8" lg="9"  align="left" style="font-size: 12px !important">
                  เมื่อออเดอร์ถููกชำระเงินเรียบร้อยแล้ว ระบบจะทำการนัดรับให้ทันที
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">  
                  <v-btn @click="shopee_connection()" color="success"
                    ><v-icon left>fa-link</v-icon> เชื่อมต่อ shopee</v-btn
                  >
                </v-col>  
              </v-row>
            </div>

            <!-- lazada -->
            <div align="center" class="my-10" v-show="dialog_type == 'lazada'">
              <v-row>
                <!-- <v-col cols="12" md="12" lg="8" align="left">
                  <v-img
                    class="elevation-3"
                    src="https://rtms3.s3-ap-southeast-1.amazonaws.com/packhai/packhai_shop/image_2020_12_03T09_01_56_704Z.png"
                  />
                </v-col> -->
                <v-col cols="6"  lg="6" align="right">
                  <v-img
                    class="elevation-3"
                    :src = "logoConnect"
                    width="190px"
                    height="190px"
                    style="border: 4px solid #fff; padding: 10px;"
                  />
                </v-col>
                <v-divider vertical ></v-divider>
                <v-col cols="6"  lg="6" align="left">
                  <v-img
                    class="elevation-3"
                    src="@/assets/lazada-logo-connect.png" 
                    width="190px"
                    height="190px"
                    style="border: 4px solid #fff; padding: 10px;"
                  />
                </v-col>
              </v-row>
            </div>
            <div
              align="center"
              class="my-10"
              v-if="this.IsDev == 'dev' && dialog_type == 'lazada'"
              >
              <v-btn dark><v-icon left>fa-link</v-icon> เชื่อมต่อ shopee</v-btn>
            </div>
            <div v-else-if="dialog_type == 'lazada'" align="center" class="my-10">
              <v-row>
                <v-col cols="12" md="3" lg="2"   align="left">คลังสินค้า : </v-col>
                <v-col
                  cols="12" md="9" lg="10" 
                
                  align="left"
                  class="font-weight-bold"
                  style="font-size: 12px !important"
                >
                  <v-select 
                      v-model="LazadaWarehouse_Selected"
                      :items="Lazada_Warehouse"
                      item-text="name"
                      item-value="id"
                      persistent-hint
                      return-object
                      dense hide-details
                      outlined
                      single-line 
                  ></v-select>

                </v-col>
              </v-row>
              <v-row>
                <v-col  cols="12" md="3" lg="2"   align="left">นัดรับอัตโนมัติ :</v-col>
                <v-col
                   cols="12" md="9" lg="10" 
                  align="left"
                  class="font-weight-bold"
                  style="font-size: 12px !important"
                >
                  <v-checkbox
                    v-model="check_init_lazada"
                    label="ต้องการให้ระบบทำการกดนัดรับโดยอัตโนมัติ"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <div v-if="check_init_lazada == true">
               <v-row class="pb-1">
                  <v-col cols="12" md="3" lg="2"   align="left">ตั้งค่านัดรับ :</v-col>
                  <v-col   cols="12" md="9" lg="10" align="left">
                    <table> 
                    <tr>
                        <td>เปิดใช้งาน</td>
                         <td>รูปแบบ</td>
                          <td>เวลาเริ่มต้น-สิ้นสุด</td>
                      <tr>
                      <tr
                        v-for="(item, index) in dayListLazada" :key="index"
                        class="font-weight-bold"
                        style="font-size: 12px !important"
                      >
                        <td  >
                          <v-switch v-model="item.isActive" :label="daysLoop[index].text" @change="daysLoopActiveChanged(item)"/>
                        </td>
                        <td  >
                          <v-radio-group row v-model="item.initType" :value="null" >
                            <v-radio label="Pickup" :value="1"></v-radio>
                            <v-radio label="Drop-off" :value="2"></v-radio>
                          </v-radio-group>
                        </td>
                        <td  >
                          <tr>
                            <td>
                              <v-select
                                v-model="item.startTime"
                                :items="items_time"
                                item-text="name"
                                item-value="value"
                                persistent-hint
                                dense hide-details
                                outlined
                                single-line
                                  style="width:90px"
                                
                              ></v-select>
                            </td>
                            <td class="pl-1 pr-1">-</td>
                            <td>
                              <v-select
                                v-model="item.endTime"
                                :items="items_time"
                                item-text="name"
                                item-value="value"
                                persistent-hint
                                dense hide-details
                                outlined
                                single-line
                                  style="width:90px"
                                 
                              ></v-select>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </div>

              <v-btn @click="lazada_connection()" color="success"
                ><v-icon left>fa-link</v-icon> เชื่อมต่อ lazada</v-btn
              >
            </div>

            <!-- ChannelSource -->
            <div align="center" v-show="dialog_type == 'vrich' || dialog_type == 'zwiz'">
              <v-form ref="formChannelSource" lazy-validation>
                <div v-if="!editVrich">
                  <v-row>
                    <v-col cols="12" md="4" lg="4" align="left">
                      <b> ChannelSource : </b>
                    </v-col>
                    <v-col cols="12" md="8" lg="8" align="left">
                      <v-select
                        v-model="channelSource"
                        :items="channelSourceList"
                        item-text="channelName"
                        item-value="id"
                        ref="refchannelSource"
                        :rules="ruleschannelSource"
                        persistent-hint
                        return-object
                        dense
                        hide-details
                        outlined
                        single-line
                      >
                        <template slot="selection" slot-scope="data">
                          <v-avatar
                            v-if="data.item.logoPic != null"
                            size="25px"
                            class="mr-2"
                            ><img :src="data.item.logoPic"
                          /></v-avatar>
                          {{ data.item.channelName }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <v-avatar
                            v-if="data.item.logoPic != null"
                            size="25px"
                            class="mr-2"
                            ><img :src="data.item.logoPic"
                          /></v-avatar>
                          {{ data.item.channelName }}
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row>
                    <v-col cols="12" md="4" lg="4" align="left">
                      <b> PushURL : </b>
                    </v-col>
                    <v-col cols="12" md="8" lg="8" align="left">
                      <v-text-field
                        :value="pushURL"
                        @change="(value) => (pushURL = Trim_value(value))"
                        ref="refpushURL"
                        :rules="rulespushURL"
                        @keyup.enter="$refs.refpushToken.focus()"
                        autocomplete="off"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" lg="4" align="left">
                      <b> PushToken : </b>
                    </v-col>
                    <v-col cols="12" md="8" lg="8" align="left">
                      <v-text-field
                        :value="pushToken"
                        @change="(value) => (pushToken = Trim_value(value))"
                        ref="refpushToken"
                        :rules="rulespushTokent"
                        @keyup.enter="EditVrich()"
                        autocomplete="off"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </div>
            <div
              align="center"
              class="my-10"
              v-if="
                this.IsDev == 'dev' && (dialog_type == 'vrich' || dialog_type == 'zwiz')
              "
              >
              <v-btn dark
                ><v-icon left>fa-link</v-icon> เชื่อมต่อ {{ dialog_type }}</v-btn
              >
            </div>
            <div
              v-else-if="dialog_type == 'vrich' || dialog_type == 'zwiz'"
              align="center"
              class="my-10"
              >
              <v-btn v-if="!editVrich" @click="ChannelSource_connection()" color="success"
                ><v-icon left>fa-link</v-icon> เชื่อมต่อ {{ dialog_type }}</v-btn
              >
              <v-btn v-else @click="EditVrich()" color="success"
                ><v-icon left>mdi-content-save</v-icon> บันทึก
              </v-btn>
            </div>

            <!-- woo -->
            <div align="center" v-show="dialog_type == 'woo'">
              <v-form ref="formShopWoo" lazy-validation>
                <v-row>
                  <v-col cols="12" md="4" lg="4" align="left">
                    <b>url ร้านค้าใน woo Commerce : </b>
                  </v-col>
                  <v-col cols="12" md="8" lg="8" align="left">
                    <v-text-field
                      :value="shopWoo.baseURL"
                      @change="(value) => (shopWoo.baseURL = Trim_value(value))"
                      ref="shopWoobaseURL"
                      :rules="shopWoo.rulesBaseURL"
                      @keyup.enter="$refs.shopWooappKey.focus()"
                      autocomplete="off"
                      outlined
                      dense
                      hide-details
                      placeholder="เช่น https://www.abc.com"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" lg="4" align="left"> <b>App Key : </b> </v-col>
                  <v-col cols="12" md="8" lg="8" align="left">
                    <v-text-field
                      :value="shopWoo.appKey"
                      @change="(value) => (shopWoo.appKey = Trim_value(value))"
                      ref="shopWooappKey"
                      :rules="shopWoo.rulesAppKey"
                      @keyup.enter="$refs.shopWooappSecret.focus()"
                      autocomplete="off"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" lg="4" align="left">
                    <b>App Secret : </b>
                  </v-col>
                  <v-col cols="12" md="8" lg="8" align="left">
                    <v-text-field
                      :value="shopWoo.appSecret"
                      @change="(value) => (shopWoo.appSecret = Trim_value(value))"
                      ref="shopWooappSecret"
                      :rules="shopWoo.rulesAppSecret"
                      @keyup.enter="$refs.shopWooWarehouseSelect.focus()"
                      autocomplete="off"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" lg="4" align="left">
                    <b>คลังที่เชื่อมต่อ : </b>
                  </v-col>
                  <v-col cols="12" md="8" lg="8" align="left">
                    <v-select 
                      v-model="WooWarehouse_Selected"
                      ref="shopWooWarehouseSelect"
                      :items="Woo_Warehouse"
                      item-text="name"
                      item-value="id"
                      persistent-hint
                      return-object
                      dense hide-details
                      outlined
                      single-line 
                  ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </div>
            <div
              align="center"
              class="my-10"
              v-if="this.IsDev == 'dev' && dialog_type == 'woo'"
              >
              <v-btn dark><v-icon left>fa-link</v-icon> เชื่อมต่อ woo</v-btn>
            </div>
            <div v-else-if="dialog_type == 'woo'" align="center" class="my-10">
              <v-btn @click="woo_connection()" color="success"
                ><v-icon left>fa-link</v-icon> เชื่อมต่อ woo</v-btn
              >
            </div>

            <!-- shopify -->
            <div align="center" v-show="dialog_type == 'shopify'">
              <v-form ref="formShopShopify" lazy-validation>
                <v-row>
                  <v-col cols="12" md="4" lg="4" align="left">
                    <b>url ร้านค้าใน Shopify : </b>
                  </v-col>
                  <v-col cols="12" md="8" lg="8" align="left">
                    <v-text-field
                      :value="shop_shopify.baseUrl"
                      @change="(value) => (shop_shopify.baseUrl = Trim_value(value))"
                      ref="shopifybaseURL"
                      :rules="shop_shopify.rulesBaseURL"
                      @keyup.enter="$refs.shopifyAdminAPI.focus()"
                      autocomplete="off"
                      outlined
                      dense
                      hide-details
                      placeholder="เช่น https://www.abc.com"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" lg="4" align="left"> <b>Admin API : </b> </v-col>
                  <v-col cols="12" md="8" lg="8" align="left">
                    <v-text-field
                      :value="shop_shopify.adminAPI"
                      @change="(value) => (shop_shopify.adminAPI = Trim_value(value))"
                      ref="shopifyAdminAPI"
                      :rules="shop_shopify.rulesAdminAPI"
                      @keyup.enter="$refs.shopifyStorefrontAPI.focus()"
                      autocomplete="off"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" lg="4" align="left">
                    <b>Storefront API : </b>
                  </v-col>
                  <v-col cols="12" md="8" lg="8" align="left">
                    <v-text-field
                      :value="shop_shopify.storefrontAPI"
                      @change="(value) => (shop_shopify.storefrontAPI = Trim_value(value))"
                      ref="shopifyStorefrontAPI"
                      :rules="shop_shopify.rulesStorefrontAPI"
                      @keyup.enter="$refs.shopifyWarehouseSelect.focus()"
                      autocomplete="off"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4" lg="4" align="left">
                    <b>คลังที่เชื่อมต่อ : </b>
                  </v-col>
                  <v-col cols="12" md="8" lg="8" align="left">
                    <v-select 
                      v-model="ShopifyWarehouse_Selected"
                      ref="shopifyWarehouseSelect"
                      :items="Shopify_Warehouse"
                      item-text="name"
                      item-value="id"
                      persistent-hint
                      return-object
                      dense hide-details
                      outlined
                      single-line 
                  ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </div>
            <div v-if="dialog_type == 'shopify'" align="right"  >
              <v-btn @click="shopify_connection()" color="primary"
                ><v-icon left>fa-link</v-icon> เชื่อมต่อ Shopify</v-btn
              >
            </div>


            <!-- token -->
            <div align="center" v-show="dialog_type == 'token'">
              <v-row>
                <v-col cols="12" md="4" lg="4" align="left">
                  <b>ช่องทางการขาย : </b>
                </v-col>
                <v-col cols="12" md="8" lg="8" align="left">
                  <v-autocomplete
                    v-model="dialog_token_chanal"
                    ref="dialog_token_chanal"
                    :items="chanal_list"
                    item-text="channelName"
                    item-value="id"
                    dense
                    hide-details
                    outlined
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" lg="4" align="left"> <b>พนักงานขาย : </b> </v-col>
                <v-col cols="12" md="8" lg="8" align="left">
                  <v-autocomplete
                    v-model="dialog_token_staff"
                    ref="dialog_token_staff"
                    :items="staff_list"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details
                    outlined
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-btn @click="connect_token()" color="success"
                  ><v-icon left>fa-link</v-icon> สร้าง Token</v-btn
                >
              </v-row>
            </div>

            <div align="center" v-show="dialog_type == 'updatewebhook'">
              <v-row>
                <v-col cols="12" md="4" lg="4" align="left">
                  <b>Webhook URL: </b>
                </v-col>
                <v-col cols="12" md="8" lg="8" align="left">
                    <v-text-field 
                      v-model="webhookUrl"
                      
                      autocomplete="off"
                      outlined
                      dense
                      hide-details 
                    ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" lg="4" align="left">
                  <b>Webhook Bearer (ถ้ามี): </b>
                </v-col>
                <v-col cols="12" md="8" lg="8" align="left">
                    <v-text-field 
                      v-model="webhookBearer"
                      autocomplete="off"
                      outlined
                      dense
                      hide-details 
                    ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" lg="4" align="left">
                
                </v-col>
                <v-col cols="12" md="8" lg="8" align="left">
                   <v-btn @click="test_webhook()" color="info" outlined>  ทดสอบ Webhook url</v-btn >
                   <span  class="green--text ml-2" v-if="isWebhookTested!=null&& isWebhookTested">สำเร็จ</span>
                   <span class="red--text ml-2" v-else-if="isWebhookTested!=null && !isWebhookTested ">ทดสอบไม่ผ่าน</span>
                </v-col>
              </v-row>
               
              <v-row>
                <v-col class="text-right">
                  <v-btn @click="save_webhook()" color="primary"
                    ><v-icon left>fa-save</v-icon> บันทึก</v-btn >
                </v-col>
              </v-row>
            </div>

            <!-- line my shop -->
            <div align="center" v-show="dialog_type == 'lineMyShop'">
              <!-- <v-row>
                <v-col cols="12" align="left">
                  <a href="https://docs.google.com/document/d/1NkJGRPvti0kXgr-uBAZUwhvkVMSqk_ywOaloG8d4SAQ/edit?usp=sharing" target="_blank"> <b>วิธีการเชื่อมต่อ line my shop</b></a>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12" md="4" lg="4" align="left"> <b>API KEY : </b> </v-col>
                <v-col cols="12" md="8" lg="8" align="left">
                  <v-text-field
                    v-model="dialog_line_my_shop.token"
                    ref="line_token"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" lg="4" align="left"> <b>ร้านค้า : </b> </v-col>
                <v-col cols="12" md="8" lg="8" align="left">
                  <v-text-field
                    v-model="dialog_line_my_shop.shopName"
                    ref="line_shopName"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" lg="4" align="left"> <b>พนักงานขาย : </b> </v-col>
                <v-col cols="12" md="8" lg="8" align="left">
                  <v-autocomplete
                    v-model="dialog_line_my_shop.salemanID"
                    ref="line_salemanID"
                    :items="staff_list"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details
                    outlined
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" lg="4" align="left"> <b>คลังที่เชื่อมต่อ : </b> </v-col>
                <v-col cols="12" md="8" lg="8" align="left">
                  <v-select 
                      v-model="LineMyShopWarehouse_Selected "
                      ref="shopWooWarehouseSelect"
                      :items="LineMyShop_Warehouse"
                      item-text="name"
                      item-value="id"
                      persistent-hint
                      return-object
                      dense hide-details
                      outlined
                      single-line 
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-btn @click="connect_line_my_shop()" color="success"
                  ><v-icon left>fa-link</v-icon> เชื่อมต่อ line my shop</v-btn
                >
              </v-row>
            </div>

            <!-- line -->
            <div align="center" class="my-10" v-show="dialog_type == 'line notify'">
              
              <v-row>
                <v-col cols="6"  lg="6" align="right">
                  <v-img
                    class="elevation-3"
                    :src = "logoConnect"
                    width="190px"
                    height="190px"
                    style="border: 4px solid #fff; padding: 10px;"
                  />
                  <!-- src="https://rtms3.s3-ap-southeast-1.amazonaws.com/packhai/packhai_shop/image_2020_11_20T03_28_17_235Z.png" -->
                </v-col>
                <v-divider vertical ></v-divider>
                <v-col cols="6"  lg="6" align="left">                  
                  <v-img
                    class="elevation-3"
                    src="@/assets/linenotify_logo.png" 
                    width="190px"
                    height="190px"
                    style="border: 4px solid #fff; padding: 10px;"
                  />
                </v-col>
              </v-row>
            </div>
            <div
              align="center"
              class="my-10"
              v-if="this.IsDev == 'dev' && dialog_type == 'line notify'"
              >
              <v-btn dark><v-icon left>fa-link</v-icon> เชื่อมต่อ line</v-btn>
            </div>
            <div v-else-if="dialog_type == 'line notify'" align="center" class="my-10">
              <v-row class="pb-5">
                <v-col cols="0" md="0" lg="2" class="pa-0 ma-0" align="left"></v-col>
                <v-col
                  cols="12"
                  md="12"
                  lg="10"
                  align="left"
                  class="py-1 font-weight-bold"
                  style="font-size: 12px !important"
                >
                  <v-radio-group v-model="lineType" class="pt-3">
                    <v-radio
                      v-for="(i, indexi) in lineTypeList"
                      :disabled="i.disabled"
                      color="info"
                      :key="indexi"
                      :label="i.name"
                      :value="i.id"
                    >
                    </v-radio>
                  </v-radio-group>
                  <v-row class="pl-5" v-if="lineType == 2">
                    <v-col class="packhai-showtime" cols="12">
                      แจ้งเตือน ณ เวลา
                      <div class="ml-3">
                        <v-select
                          v-model="hour"
                          :items="hourList"
                          item-text="data"
                          item-value="data"
                          persistent-hint
                          return-object
                          dense
                          hide-details
                          outlined
                          single-line
                        ></v-select>
                      </div>
                      <b class="ma-3"> : </b>
                      <div>
                        <v-select
                          v-model="minute"
                          :items="minuteList"
                          item-text="data"
                          item-value="data"
                          persistent-hint
                          return-object
                          dense
                          hide-details
                          outlined
                          single-line
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-btn @click="line_connection()" color="success"
                ><v-icon left>fa-link</v-icon> เชื่อมต่อ line</v-btn
              >
            </div>

            <!-- line oa -->
            <div align="center" v-show="dialog_type == 'lineOa'">
              <v-row>
                <v-col cols="12" md="4" lg="4" align="left"> <b>Token : </b> </v-col>
                <v-col cols="12" md="8" lg="8" align="left">
                  <v-text-field
                    v-model="dialog_line_oa.lineToken"
                    ref="lineoa_token"
                    autocomplete="off"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-btn @click="connect_line_oa()" color="success"
                  ><v-icon left>fa-link</v-icon> เชื่อมต่อ line oa</v-btn
                >
              </v-row>
            </div>

          </div>

          <div align="center" class="my-10" v-show="dialog_type == 'tiktok'">
            <v-row>
              <!-- <v-col cols="0" md="0" lg="2" class="pa-0 ma-0" align="left"></v-col>
              <v-col cols="12" md="12" lg="8" align="left">
                <v-img
                  class="elevation-3"
                  src="https://rtms3.s3.ap-southeast-1.amazonaws.com/packhai/packhai_shop/packhai_tiktok.png"
                />
              </v-col> -->
              <v-col cols="6"  lg="6" align="right">
                  <v-img
                    class="elevation-3"
                    :src = "logoConnect"
                    width="190px"
                    height="190px"
                    style="border: 4px solid #fff; padding: 10px;"
                  />
                </v-col>
                <v-divider vertical ></v-divider>
                <v-col cols="6"  lg="6" align="left">                  
                  <v-img
                    class="elevation-3"
                    src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/tiktok-shop-logo-png-transparent.png" 
                    width="190px"
                    height="190px"
                    style="border: 4px solid #fff; padding: 10px;background-size: contain;"
                  />
                </v-col>
            </v-row>
          </div>
          <div
            align="center"
            class="my-10"
            v-if="this.IsDev == 'dev' && dialog_type == 'tiktok'"
            >
            <v-btn dark><v-icon left>fa-link</v-icon> เชื่อมต่อ tiktok</v-btn>
          </div>
          <div v-else-if="dialog_type == 'tiktok'" align="center" class="my-10">
             <v-row>
               <v-col cols="12" md="3" lg="2"   align="left">คลังสินค้า : </v-col>
               <v-col
                 cols="12" md="9" lg="10" 
                 align="left"
                 class="py-1 font-weight-bold"
                 style="font-size: 12px !important"
               >
                 <v-select 
                     v-model="TiktokWarehouse_Selected"
                     :items="Tiktok_Warehouse"
                     item-text="name"
                     item-value="id"
                     persistent-hint
                     return-object
                     dense hide-details
                     outlined
                     single-line 
                 ></v-select>

               </v-col>
             </v-row>
             <v-row>
               <v-col cols="12" md="3" lg="2"   align="left">นัดรับอัตโนมัติ :</v-col>
               <v-col
               cols="12" md="9" lg="10" 
                
                 align="left"
                 class="font-weight-bold"
                 style="font-size: 12px !important"
               >
                 <v-checkbox
                   v-model="check_init_tiktok"
                   label="ต้องการให้ระบบทำการกดนัดรับโดยอัตโนมัติ"
                   hide-details
                 ></v-checkbox>
               </v-col>
             </v-row>

             <div v-if="check_init_tiktok == true">
              <v-row class="pb-1">
                  <v-col cols="12" md="3" lg="2"   align="left">ตั้งค่านัดรับ :</v-col>
                  <v-col   cols="12" md="9" lg="10" align="left">
                    <table > 
                      <tr>
                        <td>เปิดใช้งาน</td>
                         <td>รูปแบบ</td>
                          <td>เวลาเริ่มต้น-สิ้นสุด</td>
                      <tr>
                      <tr
                        v-for="(item, index) in dayListTiktok" :key="index"
                        class="font-weight-bold"
                        style="font-size: 12px !important"
                      >
                        <td  >
                          <v-switch v-model="item.isActive" :label="daysLoop[index].text"  @change="daysLoopActiveChanged(item)"/>
                        </td>
                        <td  >
                          <v-radio-group row v-model="item.initType" :value="null"  >
                            <v-radio label="Pickup" :value="1"></v-radio>
                            <v-radio label="Drop-off" :value="2"></v-radio>
                          </v-radio-group>
                        </td>
                        <td >
                          <tr>
                            <td>
                              <v-select
                                v-model="item.startTime"
                                :items="items_time"
                                item-text="name"
                                item-value="value"
                                persistent-hint
                                dense hide-details
                                outlined
                                single-line
                                  style="width:90px"
                                
                              ></v-select>
                            </td>
                            <td class="pl-1 pr-1">-</td>
                            <td>
                              <v-select
                                v-model="item.endTime"
                                :items="items_time"
                                item-text="name"
                                item-value="value"
                                persistent-hint
                                dense hide-details
                                outlined
                                single-line
                                  style="width:90px"
                               
                              ></v-select>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table> 
                  </v-col>
                 </v-row>
              </div>

             <v-row>
               <v-col cols="12" md="4" lg="3"   align="left"> </v-col>
               <v-col cols="12" md="8" lg="9"  align="left" style="font-size: 12px !important">
                 เมื่อออเดอร์ถููกชำระเงินเรียบร้อยแล้ว ระบบจะทำการนัดรับให้ทันที
               </v-col>
             </v-row>
             <v-row>
               <v-col cols="12">  
                <v-btn @click="tiktok_connection()" color="success"
                  ><v-icon left>fa-link</v-icon> เชื่อมต่อ tiktok</v-btn
                >
               </v-col>  
             </v-row>
           </div>

          <!-- fackbook -->
          <div v-if="fb_page_lists.length == 0">
            <div align="center" class="my-10" v-show="dialog_type == 'fackbook'">
              <v-row>
                <v-col cols="0" md="0" lg="2" class="pa-0 ma-0" align="left"></v-col>
                <v-col cols="12" md="12" lg="8" align="left">
                  <v-img
                    class="elevation-3"
                    src="https://rtms3.s3.ap-southeast-1.amazonaws.com/packhai/packhai_shop/packhai_fackbook.png"
                  />
                </v-col>
              </v-row>
            </div>
            <div
              align="center"
              class="my-10"
              v-if="this.IsDev == 'dev' && dialog_type == 'fackbook'"
              >
              <v-btn dark><v-icon left>fa-link</v-icon> เชื่อมต่อ fackbook</v-btn>
            </div>
            <div v-else-if="dialog_type == 'fackbook'" align="center" class="my-10">
              <!-- <v-btn @click="facebookLogin()" color="success">
                <v-icon left>fa-link</v-icon> เชื่อมต่อ Facebook
              </v-btn> -->
              <v-btn class="rounded-lg fontcss px-0 py-0 my-1">
                <fb-signin-button
                  :params="fbSignInParams"
                  @success="onSignInSuccess"
                  @error="onSignInError"
                >
                    Sign in with Facebook
                </fb-signin-button>
              </v-btn>
            </div>
          </div>
          <div v-else>
            <v-data-table
              v-model="fb_selected"
              :headers="headers_fackbook_for_select"
              :items="fb_page_lists"
              :single-select="false"
              item-key="id"
              show-select
              hide-default-footer
              :items-per-page="10000"
              mobile-breakpoint="0"
              class="elevation-1"
              >
            <template v-slot:item.No="{ item }">
              {{ fb_page_lists.indexOf(item) + 1 }}
            </template>
            <template v-slot:item.facebook_PagePictureURL="{ item }">
              <img style="width: 60px" :src="item.facebook_PagePictureURL" />
            </template>
            </v-data-table>
            <div class="my-5" align="center">
              <v-btn :disabled="fb_selected.length == 0" @click="fackbook_savepage()" color="success"><v-icon left>fa-link</v-icon> ตกลง</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="lazada_warehouse_dialog" scrollable :max-width="popupSize1" persistent>
      <v-card class="my-0">
        <v-card-title>
          <span>เชื่อมต่อคลังในระบบกับคลังใน Lazada</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="lazada_warehouse_dialog = false, fb_selected=[], fb_page_lists =[]"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4" lg="3"   align="left">คลังสินค้าใน Lazada : </v-col>
            <v-col
              cols="12" md="8" lg="9"
              align="left"
              class="py-1 font-weight-bold"
              style="font-size: 12px !important"
            >
              <v-select 
                  v-model="Add_LazadaWarehouse_Selected"
                  :items="Add_Lazada_Warehouse"
                  item-text="fullname"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line 
              ></v-select>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" lg="3"   align="left">คลังสินค้าในระบบ : </v-col>
            <v-col
              cols="12" md="8" lg="9" 
              align="left"
              class="py-1 font-weight-bold"
              style="font-size: 12px !important"
            >
              <v-select 
                  v-model="Add_SystemWarehouse_Selected"
                  :items="Add_System_Warehouse"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense hide-details
                  outlined
                  single-line 
              ></v-select>

            </v-col>
          </v-row>
          <v-row>
               <v-col cols="12" align="right">  
                <v-btn @click="add_lazada_warehouse()" color="success"
                  ><v-icon left>fa-plus</v-icon> เพิมคลัง</v-btn
                >
               </v-col>  
             </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialog edit init time -->
    <v-dialog v-model="dialogEdit" scrollable  persistent  >
      <v-card class="my-0">
        <v-card-title>
          <span>แก้ไขตั้งค่านัดรับ: {{dialogEditType}}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialogEdit">
            <v-icon color="danger">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>

          <!-- shopee -->
          <div align="center" class="my-5" v-show="dialogEditType == 'shopee'">
            <table>
              <tr>
                <td>เปิดใช้งาน</td>
                <td>รูปแบบ</td>
                <td>เวลาเริ่มต้น-สิ้นสุด</td>
              <tr>
              <tr
                v-for="(item, index) in dayListEditShopee" :key="index"
                class="font-weight-bold"
                style="font-size: 12px !important"
              >
                <td >
                  <v-switch v-model="item.isActive" :label="daysLoop[index].text" @change="daysLoopActiveChanged(item)"/>
                </td>
                <td >
                  <v-radio-group row v-model="item.initType" :value="null"  >
                    <v-radio label="Pickup" :value="1"></v-radio>
                    <v-radio label="Drop-off" :value="2"></v-radio>
                  </v-radio-group>
                </td>
                <td >
                  <tr>
                    <td>
                      <v-select
                        v-model="item.startTime"
                        :items="items_time"
                        item-text="name"
                        item-value="value"
                        persistent-hint
                        dense hide-details
                        outlined
                        single-line
                        style="width:100px"
                      ></v-select>
                    </td>
                    <td class="pl-1 pr-1">-</td>
                    <td>
                      <v-select
                        v-model="item.endTime"
                        :items="items_time"
                        item-text="name"
                        item-value="value"
                        persistent-hint
                        dense hide-details
                        outlined
                        single-line
                        style="width:100px"
                      ></v-select>
                    </td>
                  </tr>
                </td>
              </tr>
            </table>
            <div class="pt-10">
              <v-btn color="success" @click="updateInitTimeSetting()">บันทึก</v-btn>
            </div>
          </div>

          <!-- lazada -->
          <div align="center" class="my-5" v-show="dialogEditType == 'lazada'">
            <table>
              <tr>
                <td>เปิดใช้งาน</td>
                <td>รูปแบบ</td>
                <td>เวลาเริ่มต้น-สิ้นสุด</td>
              <tr>
              <tr
                v-for="(item, index) in dayListEditLazada" :key="index"
                class="font-weight-bold"
                style="font-size: 12px !important"
              >
                <td >
                  <v-switch v-model="item.isActive" :label="daysLoop[index].text" @change="daysLoopActiveChanged(item)"/>
                </td>
                <td >
                  <v-radio-group row v-model="item.initType" :value="null"  >
                    <v-radio label="Pickup" :value="1"></v-radio>
                    <v-radio label="Drop-off" :value="2"></v-radio>
                  </v-radio-group>
                </td>
                <td >
                  <tr>
                    <td>
                      <v-select
                        v-model="item.startTime"
                        :items="items_time"
                        item-text="name"
                        item-value="value"
                        persistent-hint
                        dense hide-details
                        outlined
                        single-line
                        style="width:100px"
                      ></v-select>
                    </td>
                    <td class="pl-1 pr-1">-</td>
                    <td>
                      <v-select
                        v-model="item.endTime"
                        :items="items_time"
                        item-text="name"
                        item-value="value"
                        persistent-hint
                        dense hide-details
                        outlined
                        single-line
                        style="width:100px"
                      ></v-select>
                    </td>
                  </tr>
                </td>
              </tr>
            </table>
            <div class="pt-10">
              <v-btn color="success" @click="updateInitTimeSetting()">บันทึก</v-btn>
            </div>
          </div>

          <!-- tiktok -->
          <div align="center" class="my-5" v-show="dialogEditType == 'tiktok'">
            <table>
              <tr>
                <td>เปิดใช้งาน</td>
                <td>รูปแบบ</td>
                <td>เวลาเริ่มต้น-สิ้นสุด</td>
              <tr>
              <tr
                v-for="(item, index) in dayListEditTiktok" :key="index"
                class="font-weight-bold"
                style="font-size: 12px !important"
              >
                <td >
                  <v-switch v-model="item.isActive" :label="daysLoop[index].text" @change="daysLoopActiveChanged(item)"/>
                </td>
                <td >
                  <v-radio-group row v-model="item.initType" :value="null"  >
                    <v-radio label="Pickup" :value="1"></v-radio>
                    <v-radio label="Drop-off" :value="2"></v-radio>
                  </v-radio-group>
                </td>
                <td >
                  <tr>
                    <td>
                      <v-select
                        v-model="item.startTime"
                        :items="items_time"
                        item-text="name"
                        item-value="value"
                        persistent-hint
                        dense hide-details
                        outlined
                        single-line
                        style="width:100px"
                      ></v-select>
                    </td>
                    <td class="pl-1 pr-1">-</td>
                    <td>
                      <v-select
                        v-model="item.endTime"
                        :items="items_time"
                        item-text="name"
                        item-value="value"
                        persistent-hint
                        dense hide-details
                        outlined
                        single-line
                        style="width:100px"
                      ></v-select>
                    </td>
                  </tr>
                </td>
              </tr>
            </table>
            <div class="pt-10">
              <v-btn color="success" @click="updateInitTimeSetting()">บันทึก</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/website/components/Loading";
import { header_token } from "@/website/backend/token";
import {
  shopCallbackService,
  stockService_dotnet,
  ecomService_dotnet,
  shopService_dotnet,
  branchService_dotnet,
  expressService_dotnet,
} from "@/website/global";
import {
  DateNow,
  FormatInvoice,
  formatMoney,
  isNumberWithDot,
  isNumberWNoDot,
  Trim_value,
  GetHourList,
  GetMinuteList,
  set_format_date_time,
} from "@/website/global_function";

import * as ShopHelper from '@/website/helper/ShopHelper.js';
export default {
  components: {
    Loading,
  },
  data: () => ({
    header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
    //day active
    dayListShopee: [],
    dayListLazada: [],
    dayListTiktok: [],

    items_time: [
      {name: '00:00', value: '00:00'},
      {name: '00:30', value: '00:30'},
      {name: '01:00', value: '01:00'},
      {name: '01:30', value: '01:30'},
      {name: '02:00', value: '02:00'},
      {name: '02:30', value: '02:30'},
      {name: '03:00', value: '03:00'},
      {name: '03:30', value: '03:30'},
      {name: '04:00', value: '04:00'},
      {name: '04:30', value: '04:30'},
      {name: '05:00', value: '05:00'},
      {name: '05:30', value: '05:30'},
      {name: '06:00', value: '06:00'},
      {name: '06:30', value: '06:30'},
      {name: '07:00', value: '07:00'},
      {name: '07:30', value: '07:30'},
      {name: '08:00', value: '08:00'},
      {name: '08:30', value: '08:30'},
      {name: '09:00', value: '09:00'},
      {name: '09:30', value: '09:30'},
      {name: '10:00', value: '10:00'},
      {name: '10:30', value: '10:30'},
      {name: '11:00', value: '11:00'},
      {name: '11:30', value: '11:30'},
      {name: '12:00', value: '12:00'},
      {name: '12:30', value: '12:30'},
      {name: '13:00', value: '13:00'},
      {name: '13:30', value: '13:30'},
      {name: '14:00', value: '14:00'},
      {name: '14:30', value: '14:30'},
      {name: '15:00', value: '15:00'},
      {name: '15:30', value: '15:30'},
      {name: '16:00', value: '16:00'},
      {name: '16:30', value: '16:30'},
      {name: '17:00', value: '17:00'},
      {name: '17:30', value: '17:30'},
      {name: '18:00', value: '18:00'},
      {name: '18:30', value: '18:30'},
      {name: '19:00', value: '19:00'},
      {name: '19:30', value: '19:30'},
      {name: '20:00', value: '20:00'},
      {name: '20:30', value: '20:30'},
      {name: '21:00', value: '21:00'},
      {name: '21:30', value: '21:30'},
      {name: '22:00', value: '22:00'},
      {name: '22:30', value: '22:30'},
      {name: '23:00', value: '23:00'},
      {name: '23:30', value: '23:30'},
    ],
    daysLoop: [
      { id: 0, text: "อาทิตย์" },
      { id: 1, text: "จันทร์" },
      { id: 2, text: "อังคาร" },
      { id: 3, text: "พุธ" },
      { id: 4, text: "พฤหัสบดี" },
      { id: 5, text: "ศุกร์" },
      { id: 6, text: "เสาร์" },
    ],

    dayAllListShopee: [],
    dayAllListLazada: [],
    dayAllListTiktok: [],

    shopeeIdEdit: null,
    lazadaIdEdit: null,
    tiktokIdEdit: null,

    dayListEditShopee: [],
    dayListEditLazada: [],
    dayListEditTiktok: [],

    dialogEdit:false,
    dialogEditType: null,

    countOpen: 0,

    //
    sync_stock_dialog:false,

    page_loading: true,
    ShopID: localStorage.getItem("shop_id"),
    StaffID: JSON.parse(localStorage.getItem("staff_id")),
    loading: false,
    language : "th",
    headers_shopee: [
      { text: "ลำดับ", align: "center", sortable: false, value: "no", width: "100px" },
      {
        text: "ID ร้านค้า",
        align: "left",
        value: "shopeeShopId",
        sortable: false,
        width: "100px",
      },
      { text: "ชื่อร้านค้า", align: "left", value: "shopeeShopName", sortable: false },
      { text: "คลังที่เชื่อมต่อ", align: "left", value: "warehouseName", sortable: false },
      {
        text: "วันที่สร้าง",
        align: "center",
        value: "created",
        sortable: false,
        width: "150px",
      },
      { text: "หมดอายุ", align: "center", value: "expired",  sortable: false, width: "150px"},
      {
        text: "นัดรับอัตโนมัติ",
        align: "center",
        value: "isAutoInit",
        sortable: false,
        width: "80px",
      },
      { text: "ลบ", align: "center", value: "isDelete", sortable: false, width: "60px" },
    ],
    lazada_warehouse_dialog:false,
    connect_shopee_data: [],
    check_init_shopee: null,
    check_init_tiktok:null,

    current_lazada_sellerID:null,
    Add_LazadaWarehouse_Selected: null,
    Add_Lazada_Warehouse: [],
    Add_SystemWarehouse_Selected: null,
    Add_System_Warehouse: [],
    headers_lazada: [
      { text: "ลำดับ", align: "center", sortable: false, value: "no", width: "100px" },
      {
        text: "ID ร้านค้า",
        align: "left",
        value: "lazadaUserId",
        sortable: false,
        width: "100px",
      },
      { text: "ชื่อร้านค้า", align: "left", value: "lazadaShopName", sortable: false },
      {
        text: "วันที่สร้าง",
        align: "center",
        value: "createDateTime",
        sortable: false,
        width: "150px",
      },
      { text: "หมดอายุ", align: "center", value: "expired",  sortable: false, width: "150px"},
      {
        text: "นัดรับอัตโนมัติ",
        align: "center",
        value: "isAutoInit",
        sortable: false,
        width: "80px",
      },
      { text: "ลบ", align: "center", value: "isDelete", sortable: false, width: "60px" },
    ],
    headers_lazada_warehouse: [
      
      {
        text: "ชื่อคลัง Lazada",
        align: "left",
        value: "lazadaWarehouseName",
        sortable: false,
       
      },
      {
        text: "Code คลัง Lazada",
        align: "left",
        value: "lazadaWarehouseCode",
        sortable: false,
       
      },
      {
        text: "ที่อยู่คลัง Lazada",
        align: "left",
        value: "lazadaWarehouseAddress",
        sortable: false,
       
      },
      {
        text: "คลังที่เชื่อมต่อในระบบ",
        align: "left",
        value: "systemWarehouse",
        sortable: false,
       
      },
      { text: "ลบ", align: "center", value: "isDelete", sortable: false, width: "60px" },
    ],
    connect_lazada_data: [],
    check_init_lazada: null,

    sync_stock_radio:false,
    sync_stock_percent: null,
    sync_stock_minimum: null,
    sync_stock_static_quantity: null,
    sync_stock_updateall:false,

    headers_vrich: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No", width: "10%" },
      { text: "Token", align: "left", value: "token", sortable: false, width: "50%" },
      { text: "PushURL", align: "left", value: "pushURL", sortable: false, width: "10%" },
      {
        text: "PushToken",
        align: "left",
        value: "pushToken",
        sortable: false,
        width: "10%",
      },
      { text: "แก้ไข", align: "center", sortable: false, value: "edit", width: "10%" },
      { text: "ลบ", align: "center", sortable: false, value: "delete", width: "60px" },
    ],
    connect_vrich_data: [],

    headers_zwiz: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No", width: "10%" },
      { text: "Token", align: "left", value: "token", sortable: false, width: "50%" },
      { text: "ลบ", align: "center", sortable: false, value: "delete", width: "60px" },
    ],
    connect_zwiz_data: [],

    headers_tiktok: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No", width: "10%" },
      { text: "ร้านค้า", align: "left", value: "shop_name", sortable: false },
      { text: "ID ร้านค้า", align: "left", value: "tiktok_shop_id", sortable: false },
      { text: "คลังที่เชื่อมต่อ", align: "left", value: "warehouseName", sortable: false },
      { text: "วันที่สร้าง", align: "center", value: "createDateTime",  sortable: false, width: "150px",},
      { text: "หมดอายุ", align: "center", value: "expired",  sortable: false, width: "150px"},
      { text: "นัดรับอัตโนมัติ",  align: "center", value: "isAutoInit", sortable: false, width: "80px",},
      { text: "ลบ", align: "center", sortable: false, value: "delete", width: "60px" },
    ],
    connect_tiktok_data: [],
    syncStockInfoList:[],
    currentsyncStockInfo:{
      platformLogo:null,
      platformShopName:null,
    },
    channelSourceList: [],
    channelSource: {},
    ruleschannelSource: [],
    apiauthenId: 0,
    token: null,
    editVrich: false,
    pushURL: null,
    pushToken: null,
    rulespushURL: [],
    rulespushTokent: [],

    headers_syncStock:[
      { text: "แก้ไข", align: "center", sortable: false, value: "edit", width: "100px" },
      { text: "ช่องทาง", align: "left", value: "platformLogo", sortable: false , width: "80px"},
      { text: "ร้านค้า", align: "left", value: "platformShopName", sortable: false, width: "300px" },
      { text: "สถานะ sync stock", align: "left", value: "isUpdateStock", sortable: false, width: "120px" },
      { text: "%การ sync", align: "left", value: "updateStockPercent", sortable: false, width: "90px" },
      { text: "หมายเหตุ", align: "left", value: "remark", sortable: false },
     
    ],
    headers_woo: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No", width: "100px" },
      { text: "URL ร้านค้า", align: "left", value: "baseURL", sortable: false },
      { text: "App Key", align: "left", value: "appKey", sortable: false },
      { text: "App Secret", align: "left", value: "appSecret", sortable: false },
      { text: "คลังที่เชื่อมต่อ", align: "left", value: "warehouseName", sortable: false },
      { text: "แก้ไข", align: "center", value: "edit", sortable: false, width: "100px" },
      { text: "ลบ", align: "center", sortable: false, value: "delete", width: "60px" },
    ],
    connect_woo_data: [],
    shopWoo: {
      id: 0,
      shopId: localStorage.getItem("shop_id"),
      baseURL: null,
      appKey: null,
      appSecret: null,
      rulesBaseURL: [],
      rulesAppKey: [],
      rulesAppSecret: [],
    },

    headers_shopify: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No", width: "100px" },
      { text: "URL ร้านค้า", align: "left", value: "baseUrl", sortable: false },
      { text: "Admin Key", align: "left", value: "adminAPI", sortable: false },
      { text: "Storefront Key", align: "left", value: "storefrontAPI", sortable: false },
      { text: "คลังที่เชื่อมต่อ", align: "left", value: "warehouseName", sortable: false },
      { text: "แก้ไข", align: "center", value: "edit", sortable: false, width: "100px" },
      { text: "ลบ", align: "center", sortable: false, value: "delete", width: "60px" },
    ],
    connect_shopify_data: [],
    shop_shopify:{
      adminAPI:"",
      id:0,
      shopID: localStorage.getItem("shop_id"),
      warehouseID:0,
      baseUrl:"", 
      storefrontAPI:""
    },

    headers_line: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No", width: "100px" },
      { text: "Token", align: "left", value: "token", sortable: false },
      {
        text: "ประเภท",
        align: "left",
        value: "typeName",
        sortable: false,
        width: "220px",
      },
      { text: "ลบ", align: "center", sortable: false, value: "delete", width: "60px" },
    ],
    connect_line_data: [],

    headers_lineoa: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No", width: "100px" },
      { text: "ชื่อ Line", align: "left", value: "lineName", sortable: false },
      { text: "PushURL", align: "left", value: "webhookUrl", sortable: false, width: "60%" },
    ],
    connect_lineoa_data: [],
    dialog_line_oa: {
      lineId: null,
      lineName: null,
      lineToken: null,
    },

    lineType: 1,
    lineTypeList: [
      { id: 1, disabled: false, name: "แจ้งเตือนสินค้าใกล้หมด" },
      { id: 3, disabled: false, name: "แจ้งเตือนเมื่อมีการเปิดออเดอร์ใหม่" },
      { id: 2, disabled: false, name: "แจ้งเตือนยอดขาย ณ สิ้นวัน" },
      { id: 4, disabled: false, name: "แจ้งเตือนเรื่องอื่นๆ" },
    ],
    hourList: GetHourList(),
    hour: { data: "00" },
    minuteList: GetMinuteList(),
    minute: { data: "00" },

    // dialog
    connect_dialog: false,
    dialog_title: null,
    dialog_type: null,
    popupSize: "45%",
    popupSize1:"60%",
    // marketplace
    shop: {
      id: localStorage.getItem("shop_id"),
      isUpdateStockMarketplace: false,
      percentUpdateStockShopee: null,
      percentUpdateStockLazada: null,
      percentUpdateStockJDCentral: null,
      percentUpdateStockVrich: null,
      percentUpdateStockZwiz: null,
      percentUpdateStockWooCommerce: null,
      percentUpdateStockLineNotify: null,
      percentUpdateStockTiktok: null,
      percentUpdateStockShopify: null,
      minimumStock: null,
      minimumStockUpdateTo: null,
      // palamiter
      is_Active: false,
      marketplaceList: [
        {
          id: 1,
          isActive: false,
          select: false,
          percent: null,
          name: "Shopee",
          photoLink:
            "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/shopee.png",
        },
        {
          id: 2,
          isActive: false,
          select: false,
          percent: null,
          name: "Lazada",
          photoLink:
            "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/lazada.png",
        },
        {
          id: 3,
          isActive: false,
          select: false,
          percent: null,
          name: "JD Central",
          photoLink:
            "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/Shop/shop20202109292223580",
        },
        {
          id: 4,
          isActive: false,
          select: false,
          percent: null,
          name: "Woo Commerce",
          photoLink:
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/woo.png",
        },
        {
          id: 5,
          isActive: false,
          select: false,
          percent: null,
          name: "Tiktok",
          photoLink:
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/TikTok-Logo-1.png",
        },
        {
          id: 6,
          isActive: false,
          select: false,
          percent: null,
          name: "Line MyShop",
          photoLink:
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/linemyshop.png",
        },
        {
          id: 7,
          isActive: false,
          select: false,
          percent: null,
          name: "Shopify",
          photoLink:
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/Screenshot+2024-03-09+004759.png",
        }
        // {
        //   id: 7,
        //   isActive: false,
        //   select: false,
        //   percent: null,
        //   name: "Vrich",
        //   photoLink: "https://dewteerapat.vrich619.com/img/logo.png",
        // },
        // {
        //   id: 8,
        //   isActive: false,
        //   select: false,
        //   percent: null,
        //   name: "Zwiz",
        //   photoLink:
        //     "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/zwizlogo.jpg",
        // },
        // {
        //   id: 9,
        //   isActive: false,
        //   select: false,
        //   percent: null,
        //   name: "Line Notify",
        //   photoLink:
        //     "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/line.png",
        // }

      ],
    },
    logoConnect:null,
    webhookBearer:null,
    webhookUrl:null,
    apiauthenid:null,
    isWebhookTested:false,
    //peak
    peak:{
      id:0,
      IsDelete:true,
      Token:null,
      PaymentMethod:null,
      MethodCode:null,
      PaymentMethodCheck:null,
      FirstTimeCheck:true,
      sendOrder:false,
      selectChannelData:[],
      nextStepAuthen:false,
      contact:null,
      uniqueID:0,
      marketplaceList: [
        {
          id: 3,
          uniqueID:0,
          isActive: false,
          select: false,
          contac: null,
          productID:null,
          MethodCode:null,
          contacValidate:null,
          productIDValidate:null,
          name: "Shopee",
          photoLink:
            "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/shopee.png",
        },
        {
          id: 4,
          uniqueID:0,
          isActive: false,
          select: false,
          contac: null,
          productID:null,
          MethodCode:null,
          contacValidate:null,
          productIDValidate:null,
          name: "Lazada",
          photoLink:
            "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/lazada.png",
        },
        {
          id: 144,
          uniqueID:0,
          isActive: false,
          select: false,
          contac: null,
          productID:null,
          MethodCode:null,
          contacValidate:null,
          productIDValidate:null,
          name: "Woo Commerce",
          photoLink:
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/woo.png",
        },
        {
          id: 11,
          uniqueID:0,
          isActive: false,
          select: false,
          contac: null,
          productID:null,
          MethodCode:null,
          contacValidate:null,
          productIDValidate:null,
          name: "Tiktok",
          photoLink:
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/TikTok-Logo-1.png",
        },
        {
          id: 8,
          uniqueID:0,
          isActive: false,
          select: false,
          contac: null,
          productID:null,
          MethodCode:null,
          contacValidate:null,
          productIDValidate:null,
          name: "Line MyShop",
          photoLink:
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/linemyshop.png",
        },
        {
          id: 6,
          uniqueID:0,
          isActive: false,
          select: false,
          contac: null,
          productID:null,
          MethodCode:null,
          contacValidate:null,
          productIDValidate:null,
          name: "Shopify",
          photoLink:
            "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/Screenshot+2024-03-09+004759.png",
        }
      ],
    },
    peakRule:{
      paymentMethodCodeRule:[],
    },
    // callBack
    data_callback: {
      id: 0,
      branchID: null,
      shopID: null,
      urlCallback: null,
      statusWaitPack: false,
      statusPacked: false,
      statusReady: false,
      statusSent: false,
      statusCancel: false,
      isDelete: false,
      isActive: false,
    },

    headers_token: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No", width: "100px" },
      { text: "Token", align: "left", value: "token", sortable: false },
      {
        text: "ช่องทางการขาย",
        align: "left",
        value: "channelName",
        sortable: false,
        width: "150px",
      },
      {
        text: "พนักงานขาย",
        align: "left",
        value: "salemanName",
        sortable: false,
        width: "150px",
      },
      {
        text: "webhook",
        align: "left",
        value: "webhookUrl",
        sortable: false,
        width: "300px",
      },
      {
        text: "ตั้งค่า Webhook",
        align: "left",
        value: "webhookSetting",
        sortable: false, 
        width: "60px"
      },
      { text: "ลบ", align: "center", sortable: false, value: "delete", width: "60px" },
    ],
    connect_token_data: [],
    Master_Warehouse:[],
    Shopee_Warehouse:[],
    Lazada_Warehouse:[],
    Tiktok_Warehouse:[],
    Woo_Warehouse:[],
    Shopify_Warehouse:[],
    LineMyShop_Warehouse:[] ,
    ShopeeWarehouse_Selected:0,
    LazadaWarehouse_Selected:0,
    TiktokWarehouse_Selected:0,
    WooWarehouse_Selected:0,
    LineMyShopWarehouse_Selected:0,
    ShopifyWarehouse_Selected:0,
    
    dialog_token_chanal: null,
    chanal_list: [],
    dialog_token_staff: null,
    staff_list: [],
    shop_data: null,
    shopInfo : JSON.parse(localStorage.getItem("shop")),
    // line my shop
    headers_line_my_shop: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No", width: "100px" },
      {
        text: "ชื่อร้าน",
        align: "left",
        value: "shopName",
        sortable: false,
        width: "150px",
      },
      {
        text: "พนักงานขาย",
        align: "left",
        value: "salemanName",
        sortable: false,
        width: "150px",
      },
      { text: "API KEY", align: "left", value: "token", sortable: false, width: "100px" },
      { text: "คลังที่เชื่อมต่อ", align: "left", value: "warehouseName", sortable: false,width: "200px"   },
      {
        text: "CallbackUrl",
        align: "left",
        value: "callBackUrl",
        sortable: false,
        width: "250px",
      },
      {
        text: "วันที่สร้าง",
        align: "left",
        value: "createdShow",
        sortable: false,
        width: "150px",
      },
      { text: "แก้ไข", align: "center", sortable: false, value: "edit", width: "60px" },
      { text: "ลบ", align: "center", sortable: false, value: "delete", width: "60px" },
    ],
    line_my_shop_data_list: [],
    line_my_shop_data_list_for_edit: [],

    dialog_line_my_shop: {
      id: 0,
      token: null,
      shopName: null,
      salemanID: null,
    },
    line_action_type: null,

    token_line: null,
    PercentUpdateStock:0,
    CurrentUpdateStock:0,
    TotalUpdateStock:0,

    IsShowPercentSyncProgress:false,
    ResyncButtonPressed:false,
    IsShowSyncSuccess:false,
    IsShowCancelSyncButton:false,
    // fb
    fbSignInParams: {
      scope: 'email, pages_show_list, pages_manage_metadata, pages_messaging, pages_manage_engagement, pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_cta, page_events',
      return_scopes: true
    },

    token_login : null,
    facebook_UserID : null,
    user_access_token : null,
    fb_page_lists : [],
    fb_selected: [],
    headers_fackbook: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No", width: "100px" },
      { text: "Logo", align: "center",value: "pagePictureURL",sortable: false, width: "60px"},
      { text: "ชื่อเพจ", align: "left",value: "pageName",sortable: false, width: "220px"},
      { text: "วันที่สร้าง", align: "center",value: "created",sortable: false, width: "160px"},
      { text: "ลบ", align: "center", value: "isDelete", sortable: false, width: "60px" },
    ],
    headers_fackbook_for_select: [
      { text: "ลำดับ", align: "center", sortable: false, value: "No"},
      { text: "Logo", align: "center",value: "facebook_PagePictureURL",sortable: false},
      { text: "ชื่อเพจ", align: "left",value: "facebook_PageName",sortable: false},
    ],
    connect_fackbook_data: [],
    show_admin_only: null,

    // ขนาดจอ
    size: "lg",
    window: {
      width: 0,
      height: 0,
    },
  }),
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    size() {
      if (this.size == "xs" || this.size == "sm") {
        this.popupSize = "95%";
        this.popupSize1 = "95%";
      } else {
        this.popupSize = "40%";
        this.popupSize1 = "60%";
      }
    },
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    await this.GetAllConnect();
    this.get_shop_call_back_url(); 
    this.get_shop_stock_allocation(); 

    this.logoConnect=this.shopInfo.logo_Square;
    await this.getPeakChannel()

    this.get_shop_market();
    this.headers_vrich[1].width = this.window.width / 2 + "px";
    this.headers_zwiz[1].width = this.window.width / 2 + "px";
    this.page_loading = false;
    this.IsDev = shopCallbackService.substring(8, 11);

    this.Master_Warehouse=  (await ShopHelper.get_warehouse_list(localStorage.getItem("shop_id"))).data;
    
    if(this.Master_Warehouse.length!=1){
      if(this.language=="th"){
        this.Master_Warehouse.unshift({id:0,name:"--กรุณาเลือกคลัง--"});
      }
      else if(this.language=="en"){
        this.Master_Warehouse.unshift({id:0,name:"--Please select warehouse--"});
      }
      else if(this.language=="cn"){
        this.Master_Warehouse.unshift({id:0,name:"--请选择仓库--"});
      }
      
    }
    this.Shopee_Warehouse =JSON.parse(JSON.stringify( this.Master_Warehouse));  
    this.Lazada_Warehouse =JSON.parse(JSON.stringify( this.Master_Warehouse));  
    this.Tiktok_Warehouse =JSON.parse(JSON.stringify( this.Master_Warehouse));  
    this.Woo_Warehouse =JSON.parse(JSON.stringify( this.Master_Warehouse));  
    this.LineMyShop_Warehouse =JSON.parse(JSON.stringify( this.Master_Warehouse));  
    this.Shopify_Warehouse =JSON.parse(JSON.stringify( this.Master_Warehouse));  
    
    this.ShopeeWarehouse_Selected=this.Shopee_Warehouse[0];
    this.LazadaWarehouse_Selected=this.Lazada_Warehouse[0];
    this.TiktokWarehouse_Selected=this.Tiktok_Warehouse[0];
    this.WooWarehouse_Selected=this.Woo_Warehouse[0];
    this.LineMyShopWarehouse_Selected=this.LineMyShop_Warehouse[0];
    this.ShopifyWarehouse_Selected=this.Shopify_Warehouse[0];

    await this.facebooksignup()
    await this.get_shop_page_list();
    await this.get_line_oa()

    if(this.$route.query.admin == 1){
      this.show_admin_only = this.$route.query.admin
    }else {
      this.show_admin_only = null
    }

    this.createDayList()
    this.CheckSyncStatus();
  },
  methods: {
    daysLoopActiveChanged(item){
      if(item.isActive){
        item.initType = 1;
      }else{
        item.initType = null;
        item.startTime = null;
        item.endTime = null
      }
    },
    async get_shop_stock_allocation(){
      var shopid=localStorage.getItem("shop_id");
      let marketplaceList = await axios.get(
        ecomService_dotnet + "Shop/get-marketplace-allocation?ShopID="+shopid ,
        { headers: this.header_token }
      );

      this.syncStockInfoList = marketplaceList.data.items;

    },
    async addToLocal(type) {
      if (type == 1) {
        localStorage.setItem("day_active_shopee", JSON.stringify(this.dayListShopee))
      } else if ( type == 2) {
        localStorage.setItem("day_active_lazada", JSON.stringify(this.dayListLazada))
      } else {
        localStorage.setItem("day_active_tiktok", JSON.stringify(this.dayListTiktok))
      }
    },
    async createDayList() {
      this.dayListShopee=[]
      this.dayListLazada=[]
      this.dayListTiktok=[]
      for (let i = 0; i < 7; i++) {
        this.dayListShopee.push({
          "dayOfWeek": i,
          "isActive": true,
          "initType":1
        })
        this.dayListLazada.push({
          "dayOfWeek": i,
          "isActive": true,
          "initType":1
        })
        this.dayListTiktok.push({
          "dayOfWeek": i,
          "isActive": true,
          "initType":1
        })
      }
    },
    openDialogEdit(item, type) {
      this.countOpen++
      this.dialogEdit = true
      this.dialogEditType = type
      if (type == 'shopee') {
        this.dayListEditShopee = []
        this.shopeeIdEdit = item.shopeeShopId
        if (item.initTimeSetting != null) {
          item.initTimeSetting.forEach((e) => {
            this.dayListEditShopee.push({
              'id': 's' + this.countOpen,
              'dayOfWeek': e.dayOfWeek,
              'endTime': e.endTime,
              'initType': e.initType,
              'isActive': e.isActive,
              'startTime': e.startTime
            })
          });
        } else {
          for (let i = 0; i < 7; i++) {
            this.dayListEditShopee.push({
              "dayOfWeek": i,
              "isActive": true,
              "initType":1
            })
          }
        }
      } else if (type == 'lazada') {
        this.dayListEditLazada = []
        this.lazadaIdEdit = item.lazadaSellerId
        if (item.initTimeSetting != null) {
          item.initTimeSetting.forEach((e) => {
            this.dayListEditLazada.push({
              'id': 'l' + this.countOpen,
              'dayOfWeek': e.dayOfWeek,
              'endTime': e.endTime,
              'initType': e.initType,
              'isActive': e.isActive,
              'startTime': e.startTime
            })
          });
        } else {
          for (let i = 0; i < 7; i++) {
            this.dayListEditLazada.push({
              "dayOfWeek": i,
              "isActive": true,
              "initType":1
            })
          }
        }
      } else if (type == 'tiktok') {
        this.dayListEditTiktok = []
        this.tiktokIdEdit = item.tiktok_shop_id
        if (item.initTimeSetting != null) {
          item.initTimeSetting.forEach((e) => {
            this.dayListEditTiktok.push({
              'id': 't' + this.countOpen,
              'dayOfWeek': e.dayOfWeek,
              'endTime': e.endTime,
              'initType': e.initType,
              'isActive': e.isActive,
              'startTime': e.startTime
            })
          });
        } else {
          for (let i = 0; i < 7; i++) {
            this.dayListEditTiktok.push({
              "dayOfWeek": i,
              "isActive": true,
              "initType":1
            })
          }
        }
      }
    },
    closeDialogEdit() {
      this.dialogEdit = false
    },
    async updateInitTimeSetting() {
      var list = []
      if (this.dialogEditType == 'shopee') {
        this.dayListEditShopee.forEach((e) => {
          list.push({
            'dayOfWeek': e.dayOfWeek,
            'isActive': e.isActive,
            'initType': e.initType,
            'startTime': e.startTime,
            'endTime': e.endTime
          })
        });
        let response = await axios.post(
          ecomService_dotnet + "Shopee/update-auto-init-timerange",
          {
            shopee_shopID: this.shopeeIdEdit,
            initTimeSetting: list
          },
          { headers: this.header_token }
        );
        this.get_data_connect_shopee()

      } else if (this.dialogEditType == 'lazada') {
        this.dayListEditLazada.forEach((e) => {
          list.push({
            'dayOfWeek': e.dayOfWeek,
            'isActive': e.isActive,
            'initType': e.initType,
            'startTime': e.startTime,
            'endTime': e.endTime
          })
        });
        let response = await axios.post(
          ecomService_dotnet + "Lazada/update-auto-init-timerange",
          {
            lazadaSellerID: this.lazadaIdEdit,
            initTimeSetting:list
          },
          { headers: this.header_token }
        );
        this.get_data_connect_lazada()

      } else if (this.dialogEditType == 'tiktok') {
        this.dayListEditTiktok.forEach((e) => {
          list.push({
            'dayOfWeek': e.dayOfWeek,
            'isActive': e.isActive,
            'initType': e.initType,
            'startTime': e.startTime,
            'endTime': e.endTime
          })
        });
        let response = await axios.post(
          ecomService_dotnet + "Tiktok/update-auto-init-timerange",
          {
            tiktok_shop_id: this.tiktokIdEdit,
            initTimeSetting: list
          },
          { headers: this.header_token }
        );
        this.get_data_connect_tiktok()

      }
      this.closeDialogEdit()
    },


    async GetAllConnect() {
      await axios
        .all([
          axios.post(
            ecomService_dotnet + "Shopee/get-shop-shopee",
            {
              shopID: localStorage.getItem("shop_id"),
            },
            { headers: this.header_token }
          ),

          axios.post(
            ecomService_dotnet + "Lazada/get-shop-lazada",
            {
              shopID: localStorage.getItem("shop_id"),
            },
            { headers: this.header_token }
          ),

          // axios.post(
          //   ecomService_dotnet + "JD/get-shop-jd",
          //   {
          //     shopID: localStorage.getItem("shop_id"),
          //   },
          //   { headers: this.header_token }
          // ),

          axios.post(
            ecomService_dotnet + "Account/GetPartnerConnections",
            {
              shopID: localStorage.getItem("shop_id"),
            },
            { headers: this.header_token }
          ),

          axios.post(
            ecomService_dotnet + "Account/GetChannelSource",
            {
              shopId: localStorage.getItem("shop_id"),
            },
            { headers: this.header_token }
          ),

          axios.post(ecomService_dotnet+'Line/get-token', {
            "shopId": localStorage.getItem("shop_id")
          }, {headers: this.header_token}),

          axios.post(
            ecomService_dotnet + "Woo/get-shopwoo",
            {
              shopId: localStorage.getItem("shop_id"),
            },
            { headers: this.header_token }
          ),
          axios.post(
            ecomService_dotnet + "Shopify/get-shop-shopify",
            {
              shopId: localStorage.getItem("shop_id"),
            },
            { headers: this.header_token }
          ),
          axios.get(
            ecomService_dotnet +
              "Tiktok/get-shop-tiktok?shopid=" +
              localStorage.getItem("shop_id"),
            { headers: this.header_token }
          ),

          axios.post(
            ecomService_dotnet + "Line/get-shop-line",
            {
              shopID: localStorage.getItem("shop_id"),
            },
            { headers: this.header_token }
          ),

          axios.get(
            expressService_dotnet + "CoreMessage/get-line-oa?shopId=" + localStorage.getItem("shop_id"),
            { headers: this.header_token }
          ),

          axios.post(
            shopService_dotnet + "ShopAccPeak/get-shop-account?shopId="+localStorage.getItem("shop_id"),
            {
            },
            { headers: this.header_token }
          )
          //
          // axios.post(stockService_dotnet+'Stock/get-shop', {
          //   "shopId": localStorage.getItem('shop_id')
          // }, {headers: this.header_token}),
        ])
        .then(
          axios.spread(
            (
              shopeeList,
              lazadaList,
              // jdList,
              APIAuthenList,
              ChannelSourceList,
              lineNotifyList,
              wooList,
              shopifyList,
              tiktokList,
              LineList,
              lineOAList,
              peakAccount
            ) => {
              // .then(axios.spread((shopeeList, lazadaList, jdList, APIAuthenList, ChannelSourceList, lineList, wooList, tiktokList, marketplaceList) => {
              // shopee
              if (shopeeList.status == 200) {
                this.connect_shopee_data = shopeeList.data;
              }
              // lazada
              if (lazadaList.status == 200) {
                this.connect_lazada_data = lazadaList.data;
              }
              // jd
              // if (jdList.status == 200) {
              //   this.connect_jd_data = jdList.data;
              // }
              // vrish and zwiz
              if (APIAuthenList.status == 200) {
                this.connect_vrich_data = APIAuthenList.data.filter(
                  (x) => x.partnerName == "vrich" || x.channelID == 36
                );
                this.connect_zwiz_data = APIAuthenList.data.filter(
                  (x) => x.partnerName == "zwiz" || x.channelID == 21
                );
                this.connect_token_data = APIAuthenList.data.filter(
                  (x) => x.channelID !=null && x.salemanID !=null
                );
              }
              // ChannelSource
              if (ChannelSourceList.status == 200) {
                this.channelSourceList = ChannelSourceList.data;
                this.channelSourceList.splice(0, 0, { id: 0, channelName: "--เลือก--" });
              }
              // line
              if (lineNotifyList.status==200){
                var data = lineNotifyList.data
                this.connect_line_data = []
                for (var i in data){
                  data[i].typeName = this.lineTypeList.filter(x => x.id == data[i].type)[0].name
                  this.connect_line_data.push(data[i])
                }
                this.LineSelectType()
              }
              // line oa
              if (lineOAList.status == 'success') {
                this.connect_lineoa_data = lineOAList.data
              }
              // woo
              if (wooList.status == 200) {
                this.connect_woo_data = wooList.data;
              }
              if (shopifyList.status == 200) {
                if(shopifyList.data.items!=null){
                  this.connect_shopify_data = shopifyList.data.items;
                }
              }
              // tiktok
              if (tiktokList.status == 200) {
                this.connect_tiktok_data = tiktokList.data;
              }
               if (LineList.status == 200) {
                this.line_my_shop_data_list = LineList.data;
              }

              //peak
              if(peakAccount.data.status === 'success')
              { 
                if(peakAccount.data.shopPeak !== null)
                {
                  const dataPeak = peakAccount.data.shopPeak
                  this.peak.FirstTimeCheck = true
                  this.peak.nextStepAuthen = true
                  this.peak.Token =dataPeak.clientToken
                  this.peak.id = dataPeak.id
                  this.peak.PaymentMethod = dataPeak.paymentMethodCode
                  this.peak.sendOrder = dataPeak.isCreateReceiptSummaryDaily
                  this.peak.IsDelete = !dataPeak.isDelete
                }else{this.peak.FirstTimeCheck = false}
              }
            }
          )
        );
    },
    sync_stock_radio_changed(){
      if(this.sync_stock_radio==1 && (this.sync_stock_percent==null ||this.sync_stock_percent=='')){
        this.sync_stock_percent=100;
      }
    },
    async get_resync_stock_percent(){
      let response = await axios.get(ecomService_dotnet + "Stock/get-resync-stock-percent?ShopID="+this.ShopID,{ headers: this.header_token });
      return response;

      // if(response.data.status=="success"){

      //   this.PercentUpdateStock=  response.data.percent;
      //   this.CurrentUpdateStock=  response.data.current;
      //   this.TotalUpdateStock= response.data.total;
      //   this.IsShowSyncSuccess=false;
      //   if( this.PercentUpdateStock>0){
      //     this.IsShowPercentSyncProgress=true;
      //   }
      //   else{
      //     if(this.ResyncButtonPressed){
      //       this.IsShowPercentSyncProgress=true;
      //       this.IsShowSyncSuccess=true;
      //     }
      //     else
      //     {
      //       this.IsShowPercentSyncProgress=false;
            
      //     }
      //   }
      
      // }else{
      //   this.PercentUpdateStock=  0;
      //   this.CurrentUpdateStock= 0;
      //   this.TotalUpdateStock= 0; 
      //   Swal.fire({
      //       type: "warning", 
      //       text: response.data.error_description,
      //     });
        
      // }
    
    },
    async cancel_resync_stock(){

      Swal.fire({
     
        type: "question",
        title: "ต้องการยกเลิกการ sync?",
        text: "กรุณายืนยันการทำรายการ",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          let response = await axios.get(ecomService_dotnet + "Stock/cancel-resync-stock?ShopID="+this.ShopID,{ headers: this.header_token });
          
          if(response.data.status=="success"){
            Swal.fire({
              position:"top",
              type: "success", 
              toast:true,
              timer:1500,
              text: "ยกเลิกเรียบร้อย",
            });
            this.IsShowCancelSyncButton=false;
            this.IsShowPercentSyncProgress=false;
            this.ResyncButtonPressed=false;
            this.IsShowSyncSuccess=false;

            this.PercentUpdateStock=0;
            this.CurrentUpdateStock=0;
            this.TotalUpdateStock=0;
          }else{
            Swal.fire({
              type: "error", 
              toast:true,
              timer:1500,
              text: response.data.error_description,
            });
          }
        }
      });


    
   
    },
    async get_shop_market() {
      let marketplaceList = await axios.post(
        shopService_dotnet + "Shop/get-shop",
        {
          shopID: localStorage.getItem("shop_id"),
        },
        { headers: this.header_token }
      );

      // marketplace
      if (marketplaceList.status == 200) {
        this.shop.isUpdateStockMarketplace = marketplaceList.data.isUpdateStockMarketplace;
        this.shop.minimumStock = marketplaceList.data.minimumStock;
        this.shop.minimumStockUpdateTo = marketplaceList.data.minimumStockUpdateTo;
        this.shop.marketplaceList[0].percent = marketplaceList.data.percentUpdateStockShopee;
        this.shop.marketplaceList[1].percent = marketplaceList.data.percentUpdateStockLazada;
        // this.shop.marketplaceList[2].percent = marketplaceList.data.percentUpdateStockJDCentral;
        this.shop.marketplaceList[3].percent = marketplaceList.data.percentUpdateStockWoo;
        this.shop.marketplaceList[4].percent = marketplaceList.data.percentUpdateStockTiktok;
        this.shop.marketplaceList[5].percent = marketplaceList.data.percentUpdateStockLine;
        this.shop.marketplaceList[6].percent = marketplaceList.data.percentUpdateStockShopify;

        this.shop.marketplaceList[0].select = ((marketplaceList.data.percentUpdateStockShopee != null && marketplaceList.data.percentUpdateStockShopee != "") ? true : false);
        this.shop.marketplaceList[1].select = ((marketplaceList.data.percentUpdateStockLazada != null && marketplaceList.data.percentUpdateStockLazada != "")? true: false);
        // this.shop.marketplaceList[2].select = ((marketplaceList.data.percentUpdateStockJDCentral != null && marketplaceList.data.percentUpdateStockJDCentral != "" )? true: false);
        this.shop.marketplaceList[3].select = ((marketplaceList.data.percentUpdateStockWoo != null && marketplaceList.data.percentUpdateStockWoo != "")? true : false);
        this.shop.marketplaceList[4].select = ((marketplaceList.data.percentUpdateStockTiktok != null && marketplaceList.data.percentUpdateStockTiktok != "")? true: false);
        this.shop.marketplaceList[5].select = ((marketplaceList.data.percentUpdateStockLine != null && marketplaceList.data.percentUpdateStockLine != "")? true: false);
        this.shop.marketplaceList[6].select = ((marketplaceList.data.percentUpdateStockShopify != null && marketplaceList.data.percentUpdateStockShopify != "")? true: false);
      }

      // Check if

      if (this.connect_shopee_data.length > 0) {
        this.shop.marketplaceList[0].isActive = true;
        this.peak.marketplaceList[0].isActive = true;
        this.shop.is_Active = true;
      }
      if (this.connect_lazada_data.length > 0) {
        this.shop.marketplaceList[1].isActive = true;
        this.peak.marketplaceList[1].isActive = true;
        this.shop.is_Active = true;
      }
      if (this.connect_woo_data.length > 0) {
        this.shop.marketplaceList[3].isActive = true;
        this.peak.marketplaceList[2].isActive = true;
        this.shop.is_Active = true;
      }
      if (this.connect_tiktok_data.length > 0) {
        this.shop.marketplaceList[4].isActive = true;
        this.peak.marketplaceList[3].isActive = true;
        this.shop.is_Active = true;
      }
      if (this.line_my_shop_data_list.length > 0) {
        this.shop.marketplaceList[5].isActive = true;
        this.peak.marketplaceList[4].isActive = true;
        this.shop.is_Active = true;
      }
      if (this.connect_shopify_data.length > 0) {
        this.shop.marketplaceList[6].isActive = true;
        this.peak.marketplaceList[5].isActive = true;
        this.shop.is_Active = true;
      }
    },

    async get_data_connect_shopify() {
      this.loading = true;
      let response = await axios.post(
        ecomService_dotnet + "Shopify/get-shop-shopify",
        {
          shopID: localStorage.getItem("shop_id"),
        },
        { headers: this.header_token }
      );
      this.connect_shopify_data = response.data.items;
      this.loading = false;
    },
    async get_data_connect_shopee() {
      this.loading = true;
      let response = await axios.post(
        ecomService_dotnet + "Shopee/get-shop-shopee",
        {
          shopID: localStorage.getItem("shop_id"),
        },
        { headers: this.header_token }
      );
      this.connect_shopee_data = response.data;
      this.loading = false;
    },
    async get_data_connect_lazada() {
      this.loading = true;
      let response = await axios.post(
        ecomService_dotnet + "Lazada/get-shop-lazada",
        {
          shopID: localStorage.getItem("shop_id"),
        },
        { headers: this.header_token }
      );
      this.connect_lazada_data = response.data;
      this.loading = false;
    },
    async get_data_connect_tiktok() {
      this.loading = true;
      let response = await axios.get(
        ecomService_dotnet + "Tiktok/get-shop-tiktok?shopid=" + localStorage.getItem("shop_id"),
        { headers: this.header_token }
      );
      this.connect_tiktok_data = response.data;
      this.loading = false;
    },
    async get_data_GetPartnerConnections() {
      let response = await axios.post(
        ecomService_dotnet + "Account/GetPartnerConnections",
        {
          shopID: localStorage.getItem("shop_id"),
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.connect_vrich_data = response.data.filter(
          (x) => x.partnerName == "vrich" || x.channelID == 36
        );
        this.connect_zwiz_data = response.data.filter(
          (x) => x.partnerName == "zwiz" || x.channelID == 21
        );
        this.connect_token_data = response.data.filter(
          (x) => x.channelID !=null && x.salemanID !=null
        );
      }
    },
    async update_IsAutoInit(id, isAutoInit, chanal) {
      this.loading = true;
      if (chanal == "shopee") {
        let response = await axios.post(
          ecomService_dotnet + "Shopee/update-shopee-is-auto-init",
          {
            id: id,
            isInit: isAutoInit,
          },
          { headers: this.header_token }
        );
        this.get_data_connect_shopee();
      } else if (chanal == "lazada") {
        let response2 = await axios.post(
          ecomService_dotnet + "Lazada/update-lazada-is-auto-init",
          {
            id: id,
            isInit: isAutoInit,
          },
          { headers: this.header_token }
        );
        this.get_data_connect_lazada();
      } else if (chanal == "tiktok") {
        let response2 = await axios.post(
          ecomService_dotnet + "Tiktok/update-tiktok-is-auto-init",
          {
            id: id,
            isInit: isAutoInit,
          },
          { headers: this.header_token }
        );
        this.get_data_connect_tiktok();
      }
      this.loading = false;
    },
   
    async DeleteApiauthen(item, typeName) {
      var massage_d = item.token;
      if (typeName == "token") {
        massage_d =
          "(ช่องทางการขาย):" +
          item.channelName +
          " / " +
          "(พนักงานขาย):" +
          item.salemanName;
      } else if (typeName == "lineMyShop") {
        massage_d =
          "(ร้านค้า):" + item.shopName + " / " + "(พนักงานขาย):" + item.salemanName;
      }
      Swal.fire({
        position: "top",
        type: "warning",
        title: "ลบ ใช่หรือไม่",
        text: massage_d,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.loading = true;
            if (typeName == "vrich") {
              let response1 = await axios.post(
                ecomService_dotnet + "Account/DeleteApiauthen",
                {
                  Id: item.id,
                },
                { headers: this.header_token }
              );
              this.connect_vrich_data.splice(this.connect_vrich_data.indexOf(item), 1);
            } else if (typeName == "zwiz") {
              let response2 = await axios.post(
                ecomService_dotnet + "Account/DeleteApiauthen",
                {
                  Id: item.id,
                },
                { headers: this.header_token }
              );
              this.connect_zwiz_data.splice(this.connect_zwiz_data.indexOf(item), 1);
            } else if (typeName == "tiktok") {
              let response3 = await axios.get(
                ecomService_dotnet +
                  "Tiktok/delete-shop-tiktok?tiktok_shop_id=" +
                  item.tiktok_shop_id,
                { headers: this.header_token }
              );
              this.connect_tiktok_data.splice(this.connect_tiktok_data.indexOf(item), 1);
            } else if (typeName == "linenotify") {
              let response3 = await axios.post(
                ecomService_dotnet + "Line/delete-token" ,
                {
                  id: item.id,
                },
                { headers: this.header_token }
              );
              this.connect_line_data.splice(this.connect_line_data.indexOf(item), 1);
              this.LineSelectType();
            } else if (typeName == "token") {
              let response = await axios.post(
                ecomService_dotnet + "ShopCallBack/delete-shop-token",
                {
                  tokenID: item.id,
                },
                { headers: this.header_token }
              );
              this.get_data_GetPartnerConnections();
            } else if (typeName == "lineMyShop") {
              let response = await axios.get(
                ecomService_dotnet +
                  "Line/delete-shop-line?ShopLineID=" +
                  item.id.toString(),
                { headers: this.header_token }
              );
              this.get_shop_line();
            }

            this.loading = false;
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              type: "success",
              title: "ลบเรียบร้อย",
            });
          } catch (e) {
            this.loading = false;
            Swal.fire({
              type: "warning",
              position: "top",
              icon: "error",
              text: "ไม่สามารถทำรายการได้",
            });
          }
        }
      });
    },
    async DeleteWoo(item) {
      Swal.fire({
        position: "top",
        type: "warning",
        title: "ลบ ใช่หรือไม่",
        text: item.baseURL,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.post(
            ecomService_dotnet + "Woo/delete-shopwoo",
            {
              id: item.id,
            },
            { headers: this.header_token }
          );
          if (response.status == 200 || response.status == 204) {
            this.connect_woo_data.splice(this.connect_woo_data.indexOf(item), 1);
            this.loading = false;
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              type: "success",
              title: "ลบเรียบร้อย",
            });
          } else {
            this.loading = false;
            Swal.fire({
              type: "warning",
              position: "top",
              icon: "error",
              text: "ไม่สามารถทำรายการได้",
            });
          }
        }
      });
    },
    async DeleteShopify(item) {
      Swal.fire({
        position: "top",
        type: "warning",
        title: "ลบ ใช่หรือไม่",
        text: item.baseURL,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.post(
            ecomService_dotnet + "Shopify/delete-shop-shopify",
            {
              id: item.id,
            },
            { headers: this.header_token }
          );
          if (response.status == 200 || response.status == 204) {
            this.connect_shopify_data.splice(this.connect_shopify_data.indexOf(item), 1);
            this.loading = false;
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              type: "success",
              title: "ลบเรียบร้อย",
            });
          } else {
            this.loading = false;
            Swal.fire({
              type: "warning",
              position: "top",
              icon: "error",
              text: "ไม่สามารถทำรายการได้",
            });
          }
        }
      });
    },
    async DeleteTokenLine(item) {
      Swal.fire({
        position: "top",
        type: "warning",
        title: "ลบ ใช่หรือไม่",
        text: item.token,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.post(
            ecomService_dotnet + "Line/delete-token",
            {
              id: item.id,
            },
            { headers: this.header_token }
          );
          if (response.status == 200 || response.status == 204) {
            this.connect_line_data.splice(this.connect_line_data.indexOf(item), 1);
            this.LineSelectType();
            this.loading = false;
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              type: "success",
              title: "ลบเรียบร้อย",
            });
          } else {
            this.loading = false;
            Swal.fire({
              type: "warning",
              position: "top",
              icon: "error",
              text: "ไม่สามารถทำรายการได้",
            });
          }
        }
      });
    },
    async DeleteShopShopee(item) {
      Swal.fire({
        position: "top",
        type: "warning",
        title: "หยุดการเชื่อมต่อ",
        text: "ร้านค้า " + item.shopeeShopName,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.post(
            ecomService_dotnet + "Shopee/delete-shop-shopee",
            {
              id: item.id,
            },
            { headers: this.header_token }
          );
          if (response.status == 200 || response.status == 204) {
            this.connect_shopee_data.splice(this.connect_shopee_data.indexOf(item), 1);
            this.loading = false;
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              type: "success",
              title: "หยุดการเชื่อมต่อ สำเร็จ",
            });
          } else {
            this.loading = false;
            Swal.fire({
              type: "warning",
              position: "top",
              icon: "error",
              text: "ไม่สามารถทำรายการได้",
            });
          }
        }
      });
    },
    async DeleteShopLazada(item) {
      Swal.fire({
        position: "top",
        type: "warning",
        title: "หยุดการเชื่อมต่อ",
        text: "ร้านค้า " + item.lazadaShopName,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.post(
            ecomService_dotnet + "Lazada/delete-shop-lazada",
            {
              id: item.id,
            },
            { headers: this.header_token }
          );
          if (response.status == 200 || response.status == 204) {
            this.connect_lazada_data.splice(this.connect_lazada_data.indexOf(item), 1);
            this.loading = false;
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              type: "success",
              title: "หยุดการเชื่อมต่อ สำเร็จ",
            });
          } else {
            this.loading = false;
            Swal.fire({
              type: "warning",
              position: "top",
              icon: "error",
              text: "ไม่สามารถทำรายการได้",
            });
          }
        }
      });
    },
    async DeleteShopLazadaWarehouse(item) {
      Swal.fire({ 
        type: "question",
        title: "แน่ใจว่าต้องการลบ",
        text: "ต้องการลบการเชื่อมต่อคลัง Lazada : " + item.lazadaWarehouseName + " กับคลังในระบบชื่อ : "+item.systemWarehouse,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true; 
          let response = await axios.post(ecomService_dotnet + "Lazada/delete-shop-lazada-warehouse?id="+ item.id,{},{ headers: this.header_token });
          if (response.status == 200  ) {

            var getlazadaResponse= await axios.post(
                                    ecomService_dotnet + "Lazada/get-shop-lazada",
                                    {
                                      shopID: localStorage.getItem("shop_id"),
                                    },
                                    { headers: this.header_token }
                                  )
            this.connect_lazada_data =getlazadaResponse.data;
            this.loading = false;
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              type: "success",
              title: "ลบการเชื่อมต่อคลังสำเร็จ",
            });
          } else {
            this.loading = false;
            Swal.fire({
              type: "warning", 
              icon: "error",
              text: "ไม่สามารถทำรายการได้",
            });
          }
        }
      });
    },
    async GetShopLazadaWarehouseForAdd(item) {

     
      this.current_lazada_sellerID =item.lazadaSellerId;
      var getlazadaWarehouseResponse= await axios.post( ecomService_dotnet + "Lazada/get-lazada-warehouse-for-add?lazadaSellerID="+item.lazadaSellerId, { }, { headers: this.header_token } )

      if(getlazadaWarehouseResponse.data.status=="success"){
        this.lazada_warehouse_dialog=true;
        this.Add_Lazada_Warehouse = getlazadaWarehouseResponse.data.lazadaWarehouseList;
        this.Add_System_Warehouse = getlazadaWarehouseResponse.data.systemWarehouseList;

        this.Add_Lazada_Warehouse.forEach(element => {
          element.fullname = element.name+" : "+element.code+" : "+element.detailAddress;
        });
        if(this.Add_Lazada_Warehouse.length>0){
          this.Add_LazadaWarehouse_Selected = this.Add_Lazada_Warehouse[0];
        }else{
          this.Add_LazadaWarehouse_Selected=null
        }

        if(this.Add_System_Warehouse.length>0){
          this.Add_SystemWarehouse_Selected = this.Add_System_Warehouse[0];
        }else{
          this.Add_SystemWarehouse_Selected=null
        }
      }else{
        Swal.fire({ 
          type: "warning",
          text:getlazadaWarehouseResponse.error_description,
        });
      }
      
    },
    async add_lazada_warehouse(){

      if(this.Add_SystemWarehouse_Selected==null || this.Add_LazadaWarehouse_Selected==null){

        this.lazada_warehouse_dialog=false;
        return;
      }
      
      var AddLazadaWarehouseResponse= await axios.post(ecomService_dotnet + "Lazada/add-shop-lazada-warehouse",{ 
        "id": 0,
        "warehouseID": this.Add_SystemWarehouse_Selected.id,
        "lazadaWarehouseCode": this.Add_LazadaWarehouse_Selected.code,
        "lazadaWarehouseName":this.Add_LazadaWarehouse_Selected.name,
        "lazadaWarehouseAddress": this.Add_LazadaWarehouse_Selected.detailAddress,
        "lazadaSellerID":this.current_lazada_sellerID
      },{ headers: this.header_token });
      if(AddLazadaWarehouseResponse.status==200){
        var getlazadaResponse= await axios.post(ecomService_dotnet + "Lazada/get-shop-lazada",{shopID: localStorage.getItem("shop_id"),},{ headers: this.header_token });
        this.connect_lazada_data =getlazadaResponse.data;
      } 
      this.lazada_warehouse_dialog=false;
    },
    // connection
    async shopee_connection() { 
      if(this.ShopeeWarehouse_Selected.id ==0){
        Swal.fire({ 
          type: "warning",
          text: "กรุณาเลือกคลังสินค้าที่ต้องการเชื่อมต่อ",
        });
        return;
      }
      this.loading = true;
      var url = document.URL;
      
      try {
        let response1 = await axios.get(ecomService_dotnet + "Shopee/get-auth?url="+url+"&warehouse_id="+this.ShopeeWarehouse_Selected.id+"&shopid="+this.ShopID+"&isInit="+ this.check_init_shopee, {
          headers: this.header_token,
        });
        var url_shopee = response1.data.url;
        this.connect_dialog = false;
        this.loading = false;
        localStorage.removeItem("check_init_shopee");
        localStorage.removeItem("shopee_connect_warehouse");
        localStorage.setItem("check_init_shopee", this.check_init_shopee);
        localStorage.setItem("shopee_connect_warehouse", this.ShopeeWarehouse_Selected.id);
        localStorage.setItem("shopee_init_timerange", JSON.stringify(this.dayListShopee) );
        
        window.location.assign(url_shopee);
      } catch (e) {
        Swal.fire({
          position: "top",
          type: "error",
          text: "เกิดข้อผิดพลาด",
        });
        this.loading = false;
      }
    },
    async lazada_connection() {
      if(this.LazadaWarehouse_Selected.id ==0){
        Swal.fire({ 
          type: "warning",
          text: "กรุณาเลือกคลังสินค้าที่ต้องการเชื่อมต่อ",
        });
        return;
      }
      this.loading = true;
      var url = document.URL;
      try {
        let response1 = await axios.get(ecomService_dotnet + "Lazada/get-auth?url="+url , {
          headers: this.header_token,
        });
        var url_lazada = response1.data.url;
        localStorage.removeItem("check_init_lazada");
        localStorage.setItem("check_init_lazada", this.check_init_lazada);
        localStorage.removeItem("lazada_connect_warehouse"); 
        localStorage.setItem("lazada_connect_warehouse", this.LazadaWarehouse_Selected.id);
        localStorage.setItem("lazada_init_timerange",JSON.stringify(this.dayListLazada)  );
        window.location.assign(url_lazada);
      } catch (e) {
        Swal.fire({
          position: "top",
          type: "error",
          text: "เกิดข้อผิดพลาด",
        });
        this.loading = false;
      }
    },
    async tiktok_connection() {
      if(this.TiktokWarehouse_Selected.id ==0){
        Swal.fire({ 
          type: "warning",
          text: "กรุณาเลือกคลังสินค้าที่ต้องการเชื่อมต่อ",
        });
        return;
      }
      this.loading = true;
      var url = document.URL;
      try {
        let response1 = await axios.get(ecomService_dotnet + "Tiktok/get-auth?url="+url, {
          headers: this.header_token,
        });
        localStorage.removeItem("check_init_tiktok");
        localStorage.removeItem("tiktok_connect_warehouse");
        localStorage.setItem("check_init_tiktok", this.check_init_tiktok);
        localStorage.setItem("tiktok_connect_warehouse", this.TiktokWarehouse_Selected.id);
        localStorage.setItem("tiktok_init_timerange", JSON.stringify(this.dayListTiktok)  );
        var url_tiktok = response1.data.url;
        window.location.assign(url_tiktok);
      } catch (e) {
        Swal.fire({
          position: "top",
          type: "error",
          text: "เกิดข้อผิดพลาด",
        });
        this.loading = false;
      }
    },

    async ChannelSource_connection() {
      await this.rulesChannelSourceCheck();
      if (this.$refs.formChannelSource.validate()) {
        this.loading = true;

        var Apiauthen = {
          Id: 0,
          PartnerId: "",
          PartnerKey: "",
          ShopId: localStorage.getItem("shop_id"),
          PartnerName: "",
          Token: "",
          Role: "",
          ChannelID: "",
          SalemanID: "",
          PushURL: "",
          PushToken: "",
        };

        if (this.dialog_type == "vrich") {
          Apiauthen.SalemanID = 525;
        } else if (this.dialog_type == "zwiz") {
          Apiauthen.SalemanID = 439;
        }
        Apiauthen.ChannelID = this.channelSource.id;
        Apiauthen.Role = "partner";
        Apiauthen.PartnerName = this.dialog_type;

        try {
          let response = await axios.post(
            ecomService_dotnet + "Account/RegisterNew",
            {
              Apiauthen: Apiauthen,
            },
            { headers: this.header_token }
          );
          if (response.status == 200) {
            if (response.data == "duplicate") {
              this.loading = false;
              Swal.fire({
                position: "top",
                type: "warning",
                text: this.channelSource.channelName + " มีการใช้งานแล้ว !!",
              });
              return;
            }
            this.get_data_GetPartnerConnections();
            this.token = response.data;
            this.loading = false;
          }
        } catch (e) {
          this.loading = false;
          Swal.fire({
            position: "top",
            type: "error",
            text: "เกิดข้อผิดพลาด",
          });
        }
      }
    },
    async EditVrich() {
      await this.rulesEditVrichCheck();
      if (this.$refs.formChannelSource.validate()) {
        this.loading = true;
        try {
          let response = await axios.post(
            ecomService_dotnet + "Account/UpdateApiauthen",
            {
              Id: this.apiauthenId,
              PushURL: this.pushURL,
              PushToken: this.pushToken,
            },
            { headers: this.header_token }
          );
          if (response.status == 200) {
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              type: "success",
              title: "Success",
            });
            this.get_data_GetPartnerConnections();
            this.loading = false;
            this.connect_dialog = false;
          }
        } catch (e) {
          this.loading = false;
          Swal.fire({
            position: "top",
            type: "error",
            text: "ไม่สามารถทำรายการได้",
          });
        }
      }
    },
    async line_connection() {
      this.loading = true;
      try {
        let response = await axios.post(
          ecomService_dotnet + "Line/authorize",
          {
            shopId: localStorage.getItem("shop_id"),
            type: this.lineType,
            time: this.lineType == 2 ? this.hour.data + ":" + this.minute.data : null,
          },
          { headers: this.header_token }
        );
        this.connect_dialog = false;
        this.loading = false;
        window.location.assign(response.data);
      } catch (e) {
        Swal.fire({
          position: "top",
          type: "error",
          text: "เกิดข้อผิดพลาด",
        });
        this.loading = false;
      }
    },
    async woo_connection() {
      await this.rulesShopWooCheck();
      if (this.$refs.formShopWoo.validate()) {
        if(this.WooWarehouse_Selected.id ==0){
          Swal.fire({ 
            type: "warning",
            text: "กรุณาเลือกคลังสินค้าที่ต้องการเชื่อมต่อ",
          });
          return;
        }
        this.shopWoo.warehouseID=this.WooWarehouse_Selected.id;
        this.loading = true;
        try {
          let response = await axios.post(
            ecomService_dotnet + "Woo/update-shopwoo",
            {
              shopWoo: this.shopWoo,
            },
            { headers: this.header_token }
          );
          this.connect_dialog = false;
          this.loading = false;
          this.connect_woo_data = [];
          this.connect_woo_data = response.data;
          Swal.fire({
            toast: true,
            timer: 1500,
            showConfirmButton: false,
            position: "top",
            type: "success",
            title: "Success",
          });
        } catch (e) {
          this.loading = false;
          Swal.fire({
            position: "top",
            type: "error",
            text: "ไม่สามารถทำรายการได้",
          });
        }
      }
    },
    async shopify_connection() {
      
      await this.rulesShopifyCheck();
      if (this.$refs.formShopShopify.validate()) {
        if(this.ShopifyWarehouse_Selected.id ==0){
          Swal.fire({ 
            type: "warning",
            text: "กรุณาเลือกคลังสินค้าที่ต้องการเชื่อมต่อ",
          });
          return;
        }
        this.shop_shopify.warehouseID=this.ShopifyWarehouse_Selected.id;
        if(this.shop_shopify.storefrontAPI==null || this.shop_shopify.storefrontAPI==""){
          return;
        }
        if(this.shop_shopify.adminAPI==null || this.shop_shopify.adminAPI==""){
          return;
        }
        if(this.shop_shopify.baseUrl==null || this.shop_shopify.baseUrl==""){
          return;
        }
        console.log( this.shop_shopify);
        this.loading = true;
        try {
          let response = await axios.post(
            ecomService_dotnet + "Shopify/update-shop-shopify",
            {
              "id": this.shop_shopify.id,
              "shopID": this.shop_shopify.shopID,
              "warehouseID": this.shop_shopify.warehouseID,
              "baseUrl": this.shop_shopify.baseUrl,
              "adminAPI":this.shop_shopify.adminAPI,
              "storefrontAPI":this.shop_shopify.storefrontAPI
            },
            { headers: this.header_token }
          );
          this.connect_dialog = false;
          this.loading = false;
          this.get_data_connect_shopify();
          Swal.fire({
            toast: true,
            timer: 1500,
            showConfirmButton: false,
            position: "top",
            type: "success",
            title: "Success",
          });
        } catch (e) {
          this.loading = false;
          Swal.fire({
            position: "top",
            type: "error",
            text: "ไม่สามารถทำรายการได้",
          });
        }
      }
    },
    // dialog
    Edit_Vrich(item) {
      this.editVrich = true;
      this.apiauthenId = item.id;
      this.token = item.token;
      this.pushURL = item.pushURL;
      this.pushToken = item.pushToken;
      this.rulesEditVrich();
    },
    SetShopWoo(item) {
       
      this.rulesShopWoo();
    
      if (item == null) {
        this.WooWarehouse_Selected = this.Woo_Warehouse[0];
        this.shopWoo.id = 0;
        this.shopWoo.baseURL = null;
        this.shopWoo.appKey = null;
        this.shopWoo.appSecret = null;
      } else {
        this.Woo_Warehouse.forEach(element => {
          if(element.id == item.warehouseID){
            this.WooWarehouse_Selected =element;
          }
        });
        this.shopWoo.id = item.id;
        this.shopWoo.baseURL = item.baseURL;
        this.shopWoo.appKey = item.appKey;
        this.shopWoo.appSecret = item.appSecret;
      }
    },
    SetShopShopify(item) {
       
        this.rulesShopify();
     
       if (item == null) {
         this.WooWarehouse_Selected = this.Woo_Warehouse[0];
         this.shop_shopify.id = 0;
         this.shop_shopify.baseUrl = null;
         this.shop_shopify.adminAPI = null;
         this.shop_shopify.storefrontAPI = null; 
       } else {
          
         this.shop_shopify.id = item.id;
         this.shop_shopify.baseUrl = item.baseUrl;
         this.shop_shopify.adminAPI = item.adminAPI;
         this.shop_shopify.storefrontAPI = item.storefrontAPI;
         this.shop_shopify.warehouseID = item.warehouseID;
         for (let index = 0; index < this.Shopify_Warehouse.length; index++) {
           
          if(this.Shopify_Warehouse[index].id ==  item.warehouseID){
            this.ShopifyWarehouse_Selected=this.Shopify_Warehouse[index]
          }
         }
        
       }
     },
    async open_dialog_topup(type) {
      this.createDayList();
      this.dialog_type = type;
      this.token = this.editVrich ? this.token : null;
      if (type == "shopee") {
        this.dialog_title = "เชื่อมต่อ shopee";
        this.check_init_shopee = false;
        this.connect_dialog = true;
      } else if (type == "lazada") {
        this.dialog_title = "เชื่อมต่อ lazada";
        this.check_init_lazada = false;
        this.connect_dialog = true;
      } else if (type == "vrich" || type == "zwiz") {
        this.dialog_title = "เชื่อมต่อ " + type;
        this.rulesChannelSource();
        this.channelSource = this.channelSourceList[0];
        this.connect_dialog = true;
      } else if (type == "line notify") {
        this.dialog_title = "เชื่อมต่อ line notify";
        this.hour = { data: "00" };
        this.minute = { data: "00" };
        this.connect_dialog = true;
      } else if (type == "woo") {
        this.dialog_title = "เชื่อมต่อ woo commerce";
        this.connect_dialog = true;
      } else if (type == "tiktok") {
        this.dialog_title = "เชื่อมต่อ tiktok";
        this.connect_dialog = true;
        this.check_init_tiktok=false;
      } else if (type == "fackbook") {
        this.dialog_title = "เชื่อมต่อ fackbook";
        this.connect_dialog = true;
      }
      else if (type == "shopify") {
        this.dialog_title = "เชื่อมต่อ Shopify";
        this.connect_dialog = true;
      } else if (type == "lineOa") {
        this.dialog_title = "เชื่อมต่อ line oa";
        this.connect_dialog = true;
      }

    },

    // marketplace
    StockMarketplaceActive(isUpdateStockMarketplace) {
      var label = "ปิดการใช้งาน";
      if (isUpdateStockMarketplace) {
        label = "เปิดการใช้งาน";
      }
      return label;
    },
    async UpdateMarketplace() {
      this.shop.percentUpdateStockShopee = ((this.shop.marketplaceList[0].percent == "") ? null : this.shop.marketplaceList[0].percent);
      this.shop.percentUpdateStockLazada = ((this.shop.marketplaceList[1].percent == "") ? null : this.shop.marketplaceList[1].percent);
      // this.shop.percentUpdateStockJDCentral = ((this.shop.marketplaceList[2].percent == "") ? null : this.shop.marketplaceList[2].percent);
      this.shop.percentUpdateStockWooCommerce = ((this.shop.marketplaceList[3].percent == "") ? null : this.shop.marketplaceList[3].percent);
      this.shop.percentUpdateStockTiktok = ((this.shop.marketplaceList[4].percent == "")  ? null : this.shop.marketplaceList[4].percent);
      this.shop.percentUpdateStockLine =  ((this.shop.marketplaceList[5].percent == "" )? null  : this.shop.marketplaceList[5].percent);
      this.shop.percentUpdateStockShopify =  ((this.shop.marketplaceList[6].percent == "" )? null  : this.shop.marketplaceList[6].percent);

      this.shop.minimumStock = ((this.shop.minimumStock == "") ? null : this.shop.minimumStock);

      this.loading = true;
      let response = await axios.post(
        stockService_dotnet + "Stock/update-marketplace",
        {
          marketplace: this.shop,
        },
        { headers: this.header_token }
      );
      this.loading = false;
      if (response.status == 200) {
        Swal.fire({
          toast: true,
          timer: 1500,
          showConfirmButton: false,
          position: "top",
          type: "success",
          title: "Success",
        });
      } else {
        Swal.fire({
          position: "top",
          type: "error",
          text: "ไม่สามารถทำรายการได้",
        });
      }
    },

    //peak
     async getUpdateShopPeak(){
      this.loading = true;
      let response = await axios.post(
        shopService_dotnet + "ShopAccPeak/update-shop-account",
        {
          id:this.peak.id,
          shopID:localStorage.getItem("shop_id"),
          clientToken:this.peak.Token,
          paymentMethodCode:this.peak.PaymentMethod,
          isCreateReceiptSummaryDaily:this.peak.sendOrder,
          isDelete: !this.peak.IsDelete,
        },
        { headers: this.header_token }
      );
      if(response.data.status === 'success')
      {
        this.loading = false;
        return response.data
      }
    },
    async getPeakChannel(){
      let response = await axios.post(
        shopService_dotnet + "ShopAccPeak/get-shop-account-setting?shopId="+localStorage.getItem("shop_id"),
        {
        },
        { headers: this.header_token }
      );
      if(response.data.status === 'success')
      {
        if(response.data.shopPeakSettings.length >0)
        {
          for(var item of response.data.shopPeakSettings)
          {
            if(item.channelSourceId === 0)
            {
              this.peak.contact = item.contactCode
              this.peak.MethodCode = item.paymentMethodCode
              this.peak.uniqueID = item.id
            }
              let index = this.peak.marketplaceList.findIndex(marketplace => marketplace.id === item.channelSourceId);
              if(index !== -1) {
                this.peak.marketplaceList[index].contac = item.contactCode;
                this.peak.marketplaceList[index].productID = item.productCode;
                this.peak.marketplaceList[index].MethodCode = item.paymentMethodCode;
                this.peak.marketplaceList[index].select = true;
                this.peak.marketplaceList[index].uniqueID = item.id
                this.handleSelectCheckboxMarketplace(this.peak.marketplaceList[index])
              }
          }
        }
      }
    },
    async handleCheckTokenAuthenClick(){
      this.loading = true;
      let response = await axios.post(
        shopService_dotnet + "ShopAccPeak/update-shop-account",
        {
          shopID:localStorage.getItem("shop_id"),
          clientToken:this.peak.Token,
          isDelete: !this.peak.IsDelete,
        },
        { headers: this.header_token }
      );
      if(response.data.status === 'success')
      {
        this.nextStepAuthen = true
        this.loading = false;
        
      }
    },
    
    async handleUpdatePeakClick()
    {
      var shopPeak 
        shopPeak =  await this.getUpdateShopPeak()
        await this.getUpdateShopAccountPeak(shopPeak.id)
    },
    

    async getUpdateShopAccountPeak(peakID){
      let response 
      if(!this.peak.sendOrder)
      {
        for(var item of this.peak.selectChannelData)
          {
            await axios.post(
            shopService_dotnet + "ShopAccPeak/update-shop-account-setting?shopId="+parseInt(localStorage.getItem("shop_id")),
            {
              id:item.uniqueID,
              shopPeakId: peakID,
              channelSourceId:item.id,
              contactCode:item.contac,
              contactName:item.name,
              productCode: item.productID,
              productName:item.name ,
              paymentMethodCode:item.MethodCode
            },
            { headers: this.header_token }
          );
        }
          Swal.fire({
             toast: true,
             timer: 1500,
             showConfirmButton: false,
             position: 'top',
             type: 'success',
             title: 'บันทึกเรียบร้อย'
           })
        
      }
      else{
        this.loading = true;
         response = await axios.post(
            shopService_dotnet + "ShopAccPeak/update-shop-account-setting?shopId="+parseInt(localStorage.getItem("shop_id")),
            {
              id:this.peak.uniqueID,
              shopPeakId: peakID,
              channelSourceId:0,
              contactCode:this.peak.contact,
              contactName:"AllOrder",
              paymentMethodCode:this.peak.MethodCode
            },
            { headers: this.header_token }
          );
        if(response.data.status === 'success') {
          Swal.fire({
             toast: true,
             timer: 1500,
             showConfirmButton: false,
             position: 'top',
             type: 'success',
             title: 'บันทึกเรียบร้อย'
           })
           this.loading = false
        }
      }


    },

    async onChangeCheckPaymentMethod(){
      if(!this.peak.FirstTimeCheck){
      if(this.peak.PaymentMethod !== null || this.peak.PaymentMethod !== '')
      {
      
      let response = await axios.post(
        shopService_dotnet + "ShopAccPeak/get-payment-method?shopId="+parseInt(localStorage.getItem("shop_id"))+"&paymentMethodCode="+this.peak.PaymentMethod,
        {
        },
        { headers: this.header_token }
      );
        if(response.data.status === 'success')
        {
          if(response.data.paymentMethods.length > 0 )
          {
            this.peak.PaymentMethodCheck = 1;
          }else{
            this.peak.PaymentMethodCheck = 2;
          }

        }
        else{
          this.peak.PaymentMethodCheck = 2;
        }
      }
    }
    },
    async onChangeCheckContact(item,index){
      if(!this.peak.FirstTimeCheck){
      let response = await axios.post(
        shopService_dotnet + "ShopAccPeak/get-contact?shopId="+parseInt(localStorage.getItem("shop_id"))+"&contactId="+item.contac,
        {
        },
        { headers: this.header_token }
      );
        if(response.data.status === 'success')
        {
          this.peak.marketplaceList[index].contacValidate = 1
        }
        else{
          this.peak.marketplaceList[index].contacValidate = 2
        }
      }
    },
    async onChangeCheckProduct(item,index){
      if(!this.peak.FirstTimeCheck)
      {
      let response = await axios.post(
        shopService_dotnet + "ShopAccPeak/get-product?shopId="+parseInt(localStorage.getItem("shop_id"))+"&productId="+item.productID,
        {
        },
        { headers: this.header_token }
      );
        if(response.data.status === 'success')
        {
          this.peak.marketplaceList[index].productIDValidate = 1
        }
        else{
          this.peak.marketplaceList[index].productIDValidate = 2
        }
      }
    },
    handleSelectCheckboxMarketplace(item){
                if (item.select){ 
                  this.peak.selectChannelData.splice(0, 0, item)
                }
                else{
                  const removeitem = this.peak.selectChannelData.findIndex(
                    (selectedItem) => selectedItem === item
                  )
                  if (removeitem !== -1) {
                    this.peak.selectChannelData.splice(removeitem, 1)
                  }
                }
          },

    // อื่นๆ
    rulesChannelSourceCheck() {
      this.ruleschannelSource = [(v) => v.id != 0 || "กรุณาระบุ"];
    },
    rulesChannelSource() {
      this.ruleschannelSource = [];
    },
    rulesEditVrichCheck() {
      this.rulespushURL = [(v) => !!v || "กรุณาระบุ"];
      this.rulespushTokent = [(v) => !!v || "กรุณาระบุ"];
    },
    rulesEditVrich() {
      this.rulespushURL = [];
      this.rulespushTokent = [];
    },
    rulesShopWooCheck() {
      this.shopWoo.rulesBaseURL = [(v) => !!v || "กรุณาระบุ"];
      this.shopWoo.rulesAppKey = [(v) => !!v || "กรุณาระบุ"];
      this.shopWoo.rulesAppSecret = [(v) => !!v || "กรุณาระบุ"];
    },
    rulesShopifyCheck() {
      this.shop_shopify.rulesBaseURL = [(v) => !!v  || "กรุณาระบุ"];
      this.shop_shopify.rulesAdminAPI= [(v) => !!v  || "กรุณาระบุ"];
      this.shop_shopify.rulesStorefrontAPI = [(v) => !!v  || "กรุณาระบุ"];
    },
    rulesShopWoo() {
      this.shopWoo.rulesBaseURL = [];
      this.shopWoo.rulesAppKey = [];
      this.shopWoo.rulesAppSecret = [];
    },
    rulesShopify() {
      this.shop_shopify.rulesBaseURL = [];
      this.shop_shopify.rulesAdminAPI = [];
      this.shop_shopify.rulesStorefrontAPI= [];
    },
    LineSelectType() {
      for (var j in this.lineTypeList) {
        var lineList = this.connect_line_data.filter(
          (x) => x.type == this.lineTypeList[j].id
        );
        if (lineList.length > 0) {
          this.lineTypeList[j].disabled = true;
        } else {
          this.lineTypeList[j].disabled = false;
        }
      }
      this.lineType = 0;
      for (var i of this.lineTypeList) {
        if (!i.disabled) {
          this.lineType = i.id;
          break;
        }
      }
      if (this.connect_line_data.length == 0) {
        this.lineType = 1;
      }
    },
    copyToken() {
      let CopyTokenCode = document.querySelector("#token-code");
      CopyTokenCode.setAttribute("type", "text");
      CopyTokenCode.select();
      document.execCommand("copy");
      CopyTokenCode.setAttribute("type", "hidden");
      this.$swal({
        toast: true,
        timer: 1500,
        showConfirmButton: false,
        position: "top",
        type: "success",
        title: "คัดลอกเรียบร้อย",
      });
    },
    copyTokenVrich(token) {
      this.token = token;
      setTimeout(() => {
        let CopyTokenVrich = document.querySelector("#token-vrich");
        CopyTokenVrich.setAttribute("type", "text");
        CopyTokenVrich.select();
        document.execCommand("copy");
        CopyTokenVrich.setAttribute("type", "hidden");
        this.$swal({
          toast: true,
          timer: 1500,
          showConfirmButton: false,
          position: "top",
          type: "success",
          title: "คัดลอกเรียบร้อย",
        });
        // window.getSelection().removeAllRanges()
      }, 100);
    },

    copyTokenLineMyshop(token) {
      this.token_line = token;
      setTimeout(() => {
        let CopyTokenVrich = document.querySelector("#token-line-my-shop");
        CopyTokenVrich.setAttribute("type", "text");
        CopyTokenVrich.select();
        document.execCommand("copy");
        CopyTokenVrich.setAttribute("type", "hidden");
        this.$swal({
          toast: true,
          timer: 1500,
          showConfirmButton: false,
          position: "top",
          type: "success",
          title: "คัดลอกเรียบร้อย",
        });
        // window.getSelection().removeAllRanges()
      }, 100);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 600) {
        this.size = "xs";
      } else if (this.window.width >= 600 && this.window.width < 960) {
        this.size = "sm";
      } else if (this.window.width >= 960 && this.window.width < 1264) {
        this.size = "md";
      } else if (this.window.width >= 1264 && this.window.width < 1904) {
        this.size = "lg";
      } else if (this.window.width >= 1904) {
        this.size = "xl";
      }
    },

    async get_shop_call_back_url() {
      try {
        this.loading = true;
        let response = await axios.post(
          ecomService_dotnet + "ShopCallBack/get-shop-callback-url",
          {
            shopID: localStorage.getItem("shop_id"),
          },
          { headers: this.header_token }
        );

        if (response.data != null && response.data != "") {
          this.data_callback = response.data;
        }
        this.loading = false;
      } catch (e) {
        await this.$swal({
          position: "top",
          type: "info",
          title: "get-shop-callback-url เกิดข้อผิดพลาด !!",
          text: e.message,
        });
      }
    },

    async add_update_shop_call_back_url() {
      try {
        this.loading = true;
        if (this.data_callback.id == 0) {
          this.data_callback.branchID = localStorage.getItem("branch_id");
          this.data_callback.shopID = localStorage.getItem("shop_id");
        }

        if (
          this.data_callback.urlCallback == null ||
          this.data_callback.urlCallback == ""
        ) {
          this.$refs.refUrlCallback.focus();
          this.loading = false;
          return;
        }

        let response = await axios.post(
          ecomService_dotnet + "ShopCallBack/update-shop-callback-url",
          {
            id: this.data_callback.id,
            branchID: this.data_callback.branchID,
            shopID: this.data_callback.shopID,
            urlCallback: this.data_callback.urlCallback,
            statusWaitPack: this.data_callback.statusWaitPack,
            statusPacked: this.data_callback.statusPacked,
            statusReady: this.data_callback.statusReady,
            statusSent: this.data_callback.statusSent,
            statusCancel: this.data_callback.statusCancel,
            isDelete: this.data_callback.isDelete,
            isActive: this.data_callback.isActive,
          },
          { headers: this.header_token }
        );

        this.get_shop_call_back_url();
        this.loading = false;
      } catch (e) {
        await this.$swal({
          position: "top",
          type: "info",
          title: "get-shop-callback-url เกิดข้อผิดพลาด !!",
          text: e.message,
        });
      }
    },

    async delete_shop_call_back_url() {
      if (this.data_callback.id != 0) {
        this.data_callback.isDelete = true;
        await this.add_update_shop_call_back_url();

        this.data_callback = {
          id: 0,
          branchID: null,
          shopID: null,
          urlCallback: null,
          statusWaitPack: false,
          statusPacked: false,
          statusReady: false,
          statusSent: false,
          statusCancel: false,
          isDelete: false,
          isActive: false,
        };
      }
    },
    async handleEditSyncStockDialog(item){
      this.currentsyncStockInfo = item;
      this.sync_stock_dialog = true;
      this.sync_stock_updateall=false;
      if(this.currentsyncStockInfo.isUpdateStock){
        this.sync_stock_radio="1";
      }else{
        this.sync_stock_radio="0";
      }
      
      this.sync_stock_percent=this.currentsyncStockInfo.updateStockPercent;
      this.sync_stock_minimum=this.currentsyncStockInfo.quantityLowStock;
      this.sync_stock_static_quantity=this.currentsyncStockInfo.staticQuantityWhenLowStock;
    },
    async CheckSyncStatus(){
      var isStop=false;
      this.IsShowSyncSuccess=false;
      this.IsShowPercentSyncProgress=false;
      let getSyncStockResponse= await this.get_resync_stock_percent();
 
      if(getSyncStockResponse.data.total==0)
      {
        this.IsShowCancelSyncButton=false;
      }
      else
      { 
        this.ResyncButtonPressed=true;
        this.IsShowCancelSyncButton=true;
        this.IsShowPercentSyncProgress=true;

        this.PercentUpdateStock=getSyncStockResponse.data.percent;
        this.CurrentUpdateStock=getSyncStockResponse.data.current;
        this.TotalUpdateStock=getSyncStockResponse.data.total;

        while(!isStop){
          getSyncStockResponse= await this.get_resync_stock_percent();
          if(getSyncStockResponse.data.total==0){
            isStop=true;
            this.IsShowSyncSuccess=true;
            this.IsShowPercentSyncProgress=false;
            this.IsShowCancelSyncButton = false;
          }else{
            this.IsShowPercentSyncProgress=true;
            this.IsShowCancelSyncButton = true;
          }
          this.PercentUpdateStock=getSyncStockResponse.data.percent;
          this.CurrentUpdateStock=getSyncStockResponse.data.current;
          this.TotalUpdateStock=getSyncStockResponse.data.total;
          setTimeout({}, 1000);
        }
      }
    },
    async ReSyncStock(){
      Swal.fire({
        type: "question",
        title: "ต้องการ sync stock ใหม่ทั้งหมด",
        text: "กรุณายืนยันทำรายการ",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if(result.value){
          this.loading=true;
          
          try {
            let response = await axios.post(
              ecomService_dotnet + "Stock/update-to-marketplace-with-shopid?ShopID="+this.ShopID,
              {},
              { headers: this.header_token }
            );
            this.loading=false;
            if(response.data.status=="error"){
              
              Swal.fire({ 
                type: "error",
                text: response.data.error_description,
              });
            }else{
              
              Swal.fire({ 
                position:"top",
                type: "success",
                toast:true,
                timer:1500,
                text:"ระบบกำลังดำเนินการ sync stock ใหม่อีกครั้ง",
              });

              this.IsShowPercentSyncProgress=true;
              this.PercentUpdateStock=0;
              this.CurrentUpdateStock=0;
              this.TotalUpdateStock=0;
              this.IsShowSyncSuccess=false;
              this.IsShowCancelSyncButton=true;

              this.ResyncButtonPressed=true;
              setTimeout(() => {
                this.CheckSyncStatus() 
              }, 5000);
             
              
              
            }
          } catch (error) {
            this.loading=false;
            Swal.fire({ 
              type: "error",
              text: "กรุณาทำรายการอีกครั้ง",
            });
          }
           
        }
      });
   
    },
    async save_edit_sync_stock(){
      var jsonRequest = {
        "id": this.currentsyncStockInfo.id,
        "platformID": this.currentsyncStockInfo.platformID,
        "shopID": this.currentsyncStockInfo.shopID,
        "isUpdateStock": (this.sync_stock_radio=="1"? true:false),
        "platformShopID": this.currentsyncStockInfo.platformShopID,
        "updateStockPercent": this.sync_stock_percent==''?null:parseFloat(this.sync_stock_percent),
        "quantityLowStock": this.sync_stock_minimum==''?null:parseInt(this.sync_stock_minimum),
        "staticQuantityWhenLowStock": this.sync_stock_static_quantity==''? null:parseInt(this.sync_stock_static_quantity),
        "isUpdateProductItem": this.sync_stock_updateall
      }

      if(jsonRequest.isUpdateStock && jsonRequest.updateStockPercent==null){
        Swal.fire({ 
          type: "error",
          text: "กรุณาระบุ % ที่ต้องการ sync stock!",
        });
        return;
      }
      this.loading=true;
      try {
        let response = await axios.post(
          ecomService_dotnet + "Shop/update-marketplace-allocation",
          jsonRequest,
          { headers: this.header_token }
        );
        if(response.data.status=="error"){
          this.loading=false;
          Swal.fire({ 
            type: "error",
            text: response.data.error_description,
          });
        }else{
          await this.get_shop_stock_allocation();
        }
      } catch (error) {
        this.loading=false;
        Swal.fire({ 
          type: "error",
          text: "กรุณาทำรายการอีกครั้ง",
        });
      }
      this.loading=false;
      this.sync_stock_dialog = false;
    },
    async get_staff_shop_list() {
      let response = await axios.post(
        shopService_dotnet + "StaffShop/get-staff-shop-list",
        {
          shopID: localStorage.getItem("shop_id"),
        },
        { headers: this.header_token }
      );
      this.staff_list = response.data;

      var data_tmp = { id: null, name: " -- เลือก -- " };
      this.staff_list.unshift(data_tmp);
    },

    async get_chanal_shop_list() {
      let response = await axios.post(
        shopService_dotnet + "Shop/get-shop-channel",
        {
          shopID: localStorage.getItem("shop_id"),
        },
        { headers: this.header_token }
      );

      this.chanal_list = response.data;
      var data_tmp = { id: null, channelName: " -- เลือก -- " };
      this.chanal_list.unshift(data_tmp);
    },

    async open_dialog_token() {
      await this.get_staff_shop_list();
      await this.get_chanal_shop_list();

      this.connect_dialog = true;
      this.dialog_title = "สร้าง Token";
      this.dialog_type = "token";
    },

    async open_dialog_update_webhook(item) {
      this.isWebhookTested=null;
      this.connect_dialog = true;
      this.webhookUrl=null;
      this.webhookBearer=null;
      this.apiauthenid=null;

      this.webhookUrl=item.webhookUrl;
      this.webhookBearer=item.webhookBearer;
      this.apiauthenid=item.id;

      this.dialog_title = "แก้ไข Webhook";
      this.dialog_type = "updatewebhook";
    },

     
    async test_webhook(){
      var message="";
       this.isWebhookTested=null;
       try {
        var headerAPI = {}

        // if(this.webhookBearer==null)
        // {
        //   headerAPI={'Content-Type': 'application/json'}
        // }
        // else
        // {
        //   headerAPI={'Content-Type': 'application/json','Authorization': 'Bearer '+this.webhookBearer}
        // }
        console.log(headerAPI);
        let response = await axios.post(this.webhookUrl,
          { test:"Testing webhook"},
          { 
            headers: headerAPI  
          }
        );
       
        if(response.status ==200){
           this.isWebhookTested=true;
        } 
        else
        {
          this.isWebhookTested=false;
        }
      } catch (error) {
        this.isWebhookTested=false;
      }
      
    },

    async save_webhook() {
      var message = "";
     
      if((this.isWebhookTested!=null && this.isWebhookTested==true) ||  this.webhookUrl==null || this.webhookUrl==""  ){    
        try {
          let response = await axios.post(
            ecomService_dotnet + "Account/UpdateWebhook",
            {
                "id": this.apiauthenid,
                "webhookUrl": this.webhookUrl,
                "webhookBearer":this.webhookBearer
            },
            { headers: this.header_token }
          );

          if(response.status ==200){
            if(response.data.status=="success"){
              Swal.fire({
                position:"top",
                type: "success", 
                toast:true,
                timer:1500,
                text: "บันทึกเรียบร้อย",
              });
              this.connect_dialog = false;
              this.get_data_GetPartnerConnections();
            }else{
              message=response.data.error_description;
            }
          } 
          else
          {
            message="ไม่สามารถทำรายการได้";
          }
        } catch (error) {
          message="ไม่สามารถทำรายการได้";
        }
      }else{
         Swal.fire({ 
          type: "warning",
          text: "กรุณาทดสอบ url ก่อนทำการบันทึก",
        }); 
      }
      if(message!=""){
        Swal.fire({ 
          type: "error",
          text: message,
        });
      }
    },
    async connect_token() {
      if (this.dialog_token_chanal == null || this.dialog_token_chanal == "") {
        this.$refs.dialog_token_chanal.focus();
        return;
      } else if (this.dialog_token_staff == null || this.dialog_token_staff == "") {
        this.$refs.dialog_token_staff.focus();
        return;
      }

      var IsNotDupicate = true;
      for (var items of this.connect_token_data) {
        if (
          items.channelID == this.dialog_token_chanal &&
          items.salemanID == this.dialog_token_staff
        ) {
          IsNotDupicate = false;
          break;
        }
      }

      if (IsNotDupicate == false) {
        this.loading = false;
        Swal.fire({
          type: "warning",
          position: "top",
          icon: "error",
          text: "มีข้อมูลอยู่แล้ว !!",
        });
        return;
      }

      if (IsNotDupicate) {
        let response = await axios.post(
          ecomService_dotnet + "Account/Register",
          {
            partnerName: this.shop.name,
            shopID: localStorage.getItem("shop_id"),
            role: "partner",
            salemanID: this.dialog_token_staff,
            channelID: this.dialog_token_chanal,
          },
          { headers: this.header_token }
        );

        this.connect_dialog = false;
        this.dialog_token_staff = null;
        this.dialog_token_chanal = null;

        this.get_data_GetPartnerConnections();
      }
    },

    async get_shop_line() {
      let response = await axios.post(
        ecomService_dotnet + "Line/get-shop-line",
        {
          shopID: localStorage.getItem("shop_id"),
        },
        { headers: this.header_token }
      );
      this.line_my_shop_data_list = response.data;
    },

   
    async get_branch() {
      let response = await axios.post(
        branchService_dotnet + "Branch/get-branch-single",
        {
          branchId: localStorage.getItem("branch_id"),
        },
        { headers: this.header_token }
      )
      if(response.status == 200)
      {
        return response.data.branch
      }
    },

    async open_dialog_line_my_shop(item, type) {
      await this.get_staff_shop_list();

      if (type == "add") {
        this.dialog_line_my_shop = {
          id: 0,
          token: null,
          shopName: null,
          salemanID: null,
        };
        this.LineMyShopWarehouse_Selected = this.LineMyShop_Warehouse[0];
        this.connect_dialog = true;
        this.dialog_title = "เชื่อมต่อ line my shop";
        this.dialog_type = "lineMyShop";
        this.line_action_type = "add";
      } else {
        this.dialog_line_my_shop = {
          id: item.id,
          token: item.token,
          shopName: item.shopName,
          salemanID: item.salemanID,
        };
        this.connect_dialog = true;
        this.dialog_title = "แก้ไข line my shop";
        this.dialog_type = "lineMyShop";
        this.line_action_type = "edit";

        this.LineMyShop_Warehouse.forEach(element => {
          if(element.id == item.warehouseID){
            this.LineMyShopWarehouse_Selected = element;
          }
        });

        // clone data
        var index = this.line_my_shop_data_list.indexOf(item);
        this.line_my_shop_data_list_for_edit = [...this.line_my_shop_data_list];
        if (this.line_my_shop_data_list_for_edit.length > 0) {
          this.line_my_shop_data_list_for_edit.splice(index, 1);
        }
      }
    },

    async connect_line_my_shop() {
      if (
        this.dialog_line_my_shop.token == null ||
        this.dialog_line_my_shop.token == ""
      ) {
        this.$refs.line_token.focus();
        return;
      } else if (
        this.dialog_line_my_shop.shopName == null ||
        this.dialog_line_my_shop.shopName == ""
      ) {
        this.$refs.line_shopName.focus();
        return;
      } else if (this.dialog_line_my_shop.salemanID == null) {
        this.$refs.line_salemanID.focus();
        return;
      }

      if(this.LineMyShopWarehouse_Selected.id ==0){
        Swal.fire({ 
          type: "warning",
          text: "กรุณาเลือกคลังสินค้าที่ต้องการเชื่อมต่อ",
        });
        return;
      } 


      if (this.line_action_type == "add") {
        var IsNotDupicate = true;
        for (var items of this.line_my_shop_data_list) {
          if (
            items.shopName == this.dialog_line_my_shop.shopName &&
            items.salemanID == this.dialog_line_my_shop.salemanID
          ) {
            IsNotDupicate = false;
            break;
          }
        }

        if (IsNotDupicate == false) {
          Swal.fire({
            type: "warning",
            position: "top",
            icon: "error",
            text: "มีข้อมูลอยู่แล้ว !!",
          });
          return;
        }

        if (IsNotDupicate) {
          this.loading = true;
          let response = await axios.post(
            ecomService_dotnet + "Line/update-shop-line",
            {
              id: this.dialog_line_my_shop.id,
              shopID: localStorage.getItem("shop_id"),
              token: this.dialog_line_my_shop.token,
              channelID: 8,
              saleManID: this.dialog_line_my_shop.salemanID,
              shopName: this.dialog_line_my_shop.shopName,
              warehouseID:this.LineMyShopWarehouse_Selected.id,
            },
            { headers: this.header_token }
          );

          this.connect_dialog = false;
          this.dialog_token_staff = null;
          this.dialog_token_chanal = null;

          this.get_shop_line();
          this.loading = false;
        }
      } else {
        var IsNotDupicate_edit = true;
        for (var items_e of this.line_my_shop_data_list_for_edit) {
          if (
            items_e.shopName == this.dialog_line_my_shop.shopName &&
            items_e.salemanID == this.dialog_line_my_shop.salemanID
          ) {
            IsNotDupicate_edit = false;
            break;
          }
        }

        if (IsNotDupicate_edit == false) {
          Swal.fire({
            type: "warning",
            position: "top",
            icon: "error",
            text: "มีข้อมูลอยู่แล้ว !!",
          });
          return;
        }

        if (IsNotDupicate_edit) {
          this.loading = true;
          let response = await axios.post(
            ecomService_dotnet + "Line/update-shop-line",
            {
              id: this.dialog_line_my_shop.id,
              shopID: localStorage.getItem("shop_id"),
              token: this.dialog_line_my_shop.token,
              channelID: 8,
              saleManID: this.dialog_line_my_shop.salemanID,
              shopName: this.dialog_line_my_shop.shopName,
              warehouseID:this.LineMyShopWarehouse_Selected.id,
            },
            { headers: this.header_token }
          );

          this.connect_dialog = false;
          this.dialog_token_staff = null;
          this.dialog_token_chanal = null;

          this.get_shop_line();
          this.loading = false;
        }
      }
    },

    async get_line_oa() {
      let response = await axios.get(
        expressService_dotnet + "CoreMessage/get-line-oa?shopId=" + localStorage.getItem("shop_id"),
        { headers: this.header_token }
      );
      this.connect_lineoa_data = response.data.data
    },

    async connect_line_oa() {
      if (this.dialog_line_oa.lineToken == null || this.dialog_line_oa.lineToken == "") {
        this.$refs.lineoa_token.focus();
        return;
      }

      let response = await axios.post(
        expressService_dotnet + "CoreMessage/update-line-oa",
        {
          "shopId": parseInt(localStorage.getItem("shop_id")),
          "lineToken": this.dialog_line_oa.lineToken,
          "isDelete": false,
        },
        { headers: this.header_token }
      );

      this.get_line_oa()
    },

    //facebook
    //#region
    async facebooksignup(){
      window.fbAsyncInit = function() {
      this.FB.init({
        appId      : '849982039770741',
        cookie     : true,
        xfbml      : true,
        version    : 'v19.0'
      });
      };
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },

    onSignInError(error) {
      this.$swal({
        position: "top",
        type: "warning",
        title: "กรุณาลองใหม่อีกครั้ง !!",
        // text: e.message,
      });
    },

    async onSignInSuccess(res) {
      console.log('res: ', res);
      var appID = '849982039770741'
      var appSecret = '79e51729474c1684a2890363a327dacc'

      window.FB.api( '/oauth/access_token', 'GET',
        {"grant_type":"fb_exchange_token","client_id":appID,"client_secret":appSecret,"fb_exchange_token":res.authResponse.accessToken},
        async resLongToken => {
          console.log('resLongToken: ', resLongToken);

          window.FB.api('/me', 'GET',
            { "fields": 'id,name,email,picture', "access_token": resLongToken.access_token},
            async resUser => {
              console.log('resUser: ', resUser);

              window.FB.api('/me/accounts?fields=name,id,access_token,picture', 'GET',
                {"access_token":resLongToken.access_token},
                async resPage => {
                  console.log('resPage: ', resPage);

                  var list = []
                  resPage.data.forEach((e) => {
                    list.push(
                      {
                        "pageId": e.id,
                        "pageName": e.name,
                        "pagePictureProfile": e.picture.data.url,
                        "pageAccessToken": e.access_token,
                        "tokenExpire": null,
                        "isDelete": false
                      }
                    )

                    window.FB.api( "/" + e.id + "/subscribed_apps?subscribed_fields=feed&access_token=" + e.access_token, "POST",
                      { "subscribed_fields": "messages, message_deliveries, message_reads" },
                      async resSub => {
                        console.log('resSub: ', resSub);
                      }
                    );

                  })
                  console.log('list: ', list);

                  if (list.length > 0) {
                    let response = await axios.post(
                      // ecomService_dotnet + "CoreMessage/connect-page-facebook",
                      'https://dev-express.packhai-api-88.com/CoreMessage/connect-page-facebook',
                      {
                        "shopID": parseInt(localStorage.getItem("shop_id")),
                        "facebookId": res.authResponse.userID,
                        "facebookName": resUser.name,
                        "facebookPictureProfile": resUser.picture.data.url,
                        "userAccessToken": resLongToken.access_token,
                        "tokenExpire": null,
                        "pages": list
                      },
                      { headers: this.header_token }
                    );
                  }
                  this.get_shop_page_list()

                }
              )

            }
          )

        }
      )

      // window.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
      //   async resUser => {
      //     console.log('resUser: ', resUser);

      //   FB.api( '/oauth/access_token', 'GET',
      //     {"grant_type":"fb_exchange_token","client_id":appID,"client_secret":appSecret,"fb_exchange_token":res.authResponse.accessToken},
      //     function(resUserLongToken) {
      //       console.log('resUserLongToken: ', resUserLongToken);

      //       FB.api('/'+ res.authResponse.userID +'/accounts', 'GET',
      //         {"access_token":resUserLongToken.access_token},
      //         function(resPage) {
      //           console.log('resPage: ', resPage);

      //             FB.api('me/accounts', 'GET', { fields: 'picture' },
      //             async resPic => {

      //               var list = []
      //               resPage.data.forEach((e) => {
      //                 list.push(
      //                   {
      //                     "pageId": e.id,
      //                     "pageName": e.name,
      //                     "pagePictureProfile": resPic.data.filter(x => x.id == e.id).length == 0 ? null : resPic.data.filter(x => x.id == e.id)[0].picture.data.url,
      //                     "pageAccessToken": e.access_token,
      //                     "tokenExpire": null,
      //                     "isDelete": false
      //                   }
      //                 )

      //                 FB.api( "/" + e.id + "/subscribed_apps?subscribed_fields=feed&access_token=" + e.access_token, "POST",
      //                   { "subscribed_fields": "messages, message_deliveries, message_reads" },
      //                   async resSub => {
      //                     console.log('resSub: ', resSub);
      //                   }
      //                 );
      //               })
      //               console.log('list: ', list);

      //               if (list.length > 0) {
      //                 let response = await axios.post(
      //                   // ecomService_dotnet + "CoreMessage/connect-page-facebook",
      //                   'https://dev-express.packhai-api-88.com/CoreMessage/connect-page-facebook',
      //                   {
      //                     "shopID": parseInt(localStorage.getItem("shop_id")),
      //                     "facebookId": res.authResponse.userID,
      //                     "facebookName": resUser.name,
      //                     "facebookPictureProfile": resUser.picture.data.url,
      //                     "userAccessToken": resUserLongToken.access_token,
      //                     "tokenExpire": null,
      //                     "pages": list
      //                   },
      //                   { headers: this.header_token }
      //                 );
      //               }
      //               this.get_shop_page_list()
      //             }
      //           )
      //         }
      //       );

      //     }
      //   );

      //     // window.FB.api('me/accounts', 'GET',
      //     //   async resPage => {
      //     //     console.log('resPage: ', resPage);

      //     //     FB.api('me/accounts', 'GET', { fields: 'picture' },
      //     //       async resPic => {

      //     //         var list = []
      //     //         resPage.data.forEach((e) => {
      //     //           list.push(
      //     //             {
      //     //               "pageId": e.id,
      //     //               "pageName": e.name,
      //     //               "pagePictureProfile": resPic.data.filter(x => x.id == e.id).length == 0 ? null : resPic.data.filter(x => x.id == e.id)[0].picture.data.url,
      //     //               "pageAccessToken": e.access_token,
      //     //               "tokenExpire": null,
      //     //               "isDelete": false
      //     //             }
      //     //           )

      //     //           FB.api( "/" + e.id + "/subscribed_apps?subscribed_fields=feed&access_token=" + e.access_token, "POST",
      //     //             { "subscribed_fields": "messages, message_deliveries, message_reads" },
      //     //             async resSub => {

      //     //             }
      //     //           );
      //     //         })

      //     //         if (list.length > 0) {
      //     //           let response = await axios.post(
      //     //             // ecomService_dotnet + "CoreMessage/connect-page-facebook",
      //     //             'https://dev-express.packhai-api-88.com/CoreMessage/connect-page-facebook',
      //     //             {
      //     //               "shopID": parseInt(localStorage.getItem("shop_id")),
      //     //               "facebookId": res.authResponse.userID,
      //     //               "facebookName": resUser.name,
      //     //               "facebookPictureProfile": resUser.picture.data.url,
      //     //               "userAccessToken": res.authResponse.accessToken,
      //     //               "tokenExpire": null,
      //     //               "pages": list
      //     //             },
      //     //             { headers: this.header_token }
      //     //           );
      //     //         }
      //     //         this.get_shop_page_list()
      //     //       }
      //     //     )
      //     //   }
      //     // )
      //   }
      // )
      // this.connect_dialog = false;
    },
    //#endregion

    async get_shop_page_list () {
      let response = await axios.post(
        // expressService_dotnet + "CoreMessage/get-page-facebook-list",
        'https://dev-express.packhai-api-88.com/CoreMessage/get-page-facebook-list',
        {
          "shopID": localStorage.getItem("shop_id"),
        },
        { headers: this.header_token }
      )
      console.log(response.data);
      if (response.data.page != null) {
        this.connect_fackbook_data = response.data.page
      } else {
        this.connect_fackbook_data = []
      }
    },

    async deleteFacebook(item) {
      let response = await axios.post(
        // expressService_dotnet + "CoreMessage/delete-facebook-page",
        'https://dev-express.packhai-api-88.com/CoreMessage/delete-facebook-page',
        {
          "shopID": parseInt(localStorage.getItem("shop_id")),
          "pageId": item.pageID
        },
        { headers: this.header_token }
      )
      if (response.data.status == 'success') {
        this.get_shop_page_list()
      }
    },

    async facebookLogin(){
      // alert("1");
      console.log(header_token);
      const response = await axios.get(
        expressService_dotnet + 'FacebookMessage/login-facebook' ,
        { headers: header_token }
      );
        console.log("Response:", JSON.stringify(response));
        // alert("2")
      if (response.data.loginUrl) {
        console.log("Redirecting to:", response.data);
        // alert("Redirecting to:" + response.data);
        window.location.href = response.data.loginUrl;
      } else {
        console.error("Invalid response format:", response.data);
        // alert("Invalid response format: " + JSON.stringify(response.data));
      }
    },
    async exchangeCodeForPageAccessToken(code) {
      try {
        // alert('เข้า exchangeCodeForPageAccessToken')
        const shopId = localStorage.getItem("shop_id");
        let response = await axios.get(
          `${expressService_dotnet} + FacebookMessage/page-callback?code=${code}&shopId=${shopId}`,
          { headers: header_token }
        );
        console.log('Response from page-callback:', response);
        const newUrl = window.location.origin + window.location.pathname;
        window.location.href = newUrl;
      } catch (error) {
        console.error('Error exchanging code for page access token:', error);
      }
    },
    async handleFacebookCallback() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const error = urlParams.get('error');
      const callback = urlParams.get('callback');
      if (error) {
        console.error('Error during Facebook authorization:', error);
        return;
      }
      if (code) {
        if (callback === 'page-callback') {
          await this.exchangeCodeForPageAccessToken(code);
          await this.get_shop_page_list();
        }
      }
    },

    isNumberWNoDot,
    isNumberWithDot,
    Trim_value,
    GetHourList,
    GetMinuteList,
    set_format_date_time,
    formatMoney,

  },

  mounted() {
    this.handleFacebookCallback();
  }
};
</script>
<style>
.packhai-border-table {
  /* border:1px solid #e9edf4; */
  border-collapse: collapse;
  border-spacing: 0px;
  border-right: 1px solid #e9edf4;
  border-bottom: 1px solid #e9edf4;
  border-top: 1px solid #e9edf4;
}
.packhai-border-table thead {
  color: white;
}
.packhai-border-table thead tr th {
  color: black !important;
  font-weight: bold;
  font-size: 14px;
}
.packhai-border-table tr {
  /* border-collapse: collapse; */
  /* border: 1px solid #e9edf4; */
}
@media (min-width: 961px) {
  .v-dialog {
    max-width: 750px  ;
  }
}
@media (max-width: 960px) {
  .v-dialog {
    max-width: 80%  ;
  }
}
 
.packhai-border-table th {
  /* border-collapse: collapse; */
  border-left: 1px solid #e9edf4;
}
.packhai-border-table tr td {
  border-left: 1px solid #e9edf4;
}
.height-70{
  height: 70px;
}

.fb-signin-button {
  display: inline-block;
  padding: 13px;
  width: 100%;
  border-radius: 3px;
  background-color: #4267b2;
  color: #fff;
}
</style>
